
import { defineComponent, computed, ref, onMounted } from 'vue';
import { Course, getIconUrl } from "../../data/courses"
import { IonButtons, IonRow, IonGrid, IonCol } from '@ionic/vue';
import { personCircle, shareOutline, play, ellipsisHorizontalOutline } from 'ionicons/icons';
import InlineSvg from 'vue-inline-svg';
import { ActionTypes } from '@/store/action-types';
export default defineComponent({
    props: {
        course: {
            type: Object as () => Course,
            required: true,
            description: "Course that is being viewed"
        }
    },
    components: {
      InlineSvg
    },
    data() {
      return {
        play,
        ellipsisHorizontalOutline
      }
    },
    setup(props, context) {
      const icon_url = ref<string>("")
      const random_number = ref<number>()
      onMounted(() => {
        if(props.course) {
          if(props.course.icon_file_url == null) {
            context.emit('svgLoaded')
          } else {
            icon_url.value = getIconUrl(props.course)
          }
        }
        random_number.value = Math.random()
      });
      return {
        icon_url,
        random_number
      }
    },  
})
