import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../../../resources/campGeo_Logo_large.png'


const _withScopeId = n => (_pushScopeId("data-v-4cc6d595"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "login-toolbar-logo m-0-auto p-20",
      src: _imports_0,
      onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoLoaded && _ctx.logoLoaded(...args)))
    }, null, 32)
  ]))
}