
import { CampGeoApiService } from '@/services/CampGeoApiService';
import ChapterListItem from '@/components/ChapterListItem.vue';
import { onMounted, ref, computed } from 'vue'
import { useRoute } from 'vue-router';
import { IonButton, IonList } from '@ionic/vue';
import { Course, getAmountString } from '@/data/courses';
import PageTemplate from './PageTemplate.vue';
import courseBanner from './components/course-banner.vue';
export default {
    components: {
        IonButton,
        courseBanner,
        ChapterListItem,
        IonList,
        PageTemplate
    },
    setup() {
        const route = useRoute();
        const course = ref<Course|undefined>()
        const ready = ref(false)
        const loading_stripe_url = ref(false)

        const amount_string = computed(() => {
            if(typeof course.value != 'undefined') {
                return getAmountString(course.value)
            }
            return null
        })
        const openUrl = () => {
            loading_stripe_url.value = true
            if(course.value) {
              CampGeoApiService.getCoursePaymentUrl(course.value).then(payment_url => {
                window.location.href = payment_url
              })
              .catch(message => {
                console.error(message);
                loading_stripe_url.value = false
              })
            }

        }
        onMounted(() => {
            CampGeoApiService.getCourseBySlug(route.params.slug as string, true).then(c => { 
                course.value = c;
                ready.value = true
                
            })
        })
        return {
            course,
            ready,
            amount_string,
            openUrl,
            loading_stripe_url
        }
    }
}
