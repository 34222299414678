import { useRoute, useRouter } from 'vue-router';
export const useLandingPageApi = () => {

    const router = useRouter()
    const route = useRoute()
    
    const goToLogin = () => {
        router.push({path: '/login', query: route.query});
    }
    
    const goToSignup = () => {
        router.push({path: '/signup', query: route.query})
    }

    return {
        goToLogin,
        goToSignup
    }
}