
import { defineComponent, onMounted, ref, computed, watch } from "@vue/runtime-core";
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/css/zoom'

  import {Pagination, Scrollbar, Navigation,} from 'swiper';
import { PodcastMedia, Episode } from "@/data/episodes";
import PodcastMediaView from "./PodcastMediaView.vue"

  import { IonCard } from '@ionic/vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
import { useStore } from "@/store";
import { inheritsComments } from "@babel/types";
import { CampGeoApiService, ImageSwipeAction } from "@/services/CampGeoApiService";
export default defineComponent({
    props: {
        mediaItems: {
            type: Array as () => Array<PodcastMedia>,
            required: false,
            default: () => [],
            description: "Media items being scrolled through"
        },

        episode: {
            type: Object as () => Episode,
            required: true
        }
    },
    data:() => ({
        swiper: null,
        duration_array: []
    }),
    setup(props, context) {
        const current_index = ref(0)
        const swiper = ref(undefined)
        const onSwiper = incoming_swiper => {
            swiper.value = incoming_swiper
            current_index.value = incoming_swiper.realIndex
        }
        const showImageOverlay = (new_overlay_image: string) => {
            context.emit('showImageOverlay', new_overlay_image)
        }
        const image_loaded_count = ref<number>(0)
        const imageLoaded = () => {
            image_loaded_count.value++ 
            if(image_loaded_count.value == props.mediaItems.length) {
                context.emit('imagesLoaded')
            }
        }
        const transitioning = ref(false)

        const transitionStart = () => {
            transitioning.value = true
        }
        const transitionEnd = () => {
            transitioning.value = false
        }
        
        const store = useStore()
        const playing_episode = computed(() => {
            return store.getters.playing_episode
        })
        const is_playing = computed(() => {
            return store.getters.is_playing && playing_episode.value && props.episode && playing_episode.value.id == props.episode.id;
        })

        const playing_episode_current_time = () => {
            return store.getters.playing_episode_current_time
        }

        watch(playing_episode_current_time, newVal => {
            if(is_playing.value) {
                const index = props.mediaItems.findIndex(item => {
                    return item.timestamp == newVal
                })
                if(index >= 0 && swiper.value) {
                    //@ts-ignore
                    swiper.value.slideTo(index)
                }
            }
        })
        const slider_moved_manually = ref(false)
        const sliderMove = ($event) => {
            slider_moved_manually.value = true
        }
        const snapIndexChange = incoming_swiper => {
            const from_index = current_index.value
            current_index.value = incoming_swiper.realIndex
            if (from_index == current_index.value) {
                return;
            }
            const listener = store.getters.user_id
            if(typeof listener == "undefined") {
                throw "no active listener"
            }
            const from_podcast_media_id = props.mediaItems[from_index].id
            const to_podcast_media_id = props.mediaItems[current_index.value].id
            const isa: ImageSwipeAction = {
                created_at: new Date(),
                from_podcast_media: from_podcast_media_id,
                to_podcast_media: to_podcast_media_id,
                slide_automatically_moved: !slider_moved_manually,
                listener: listener
            }

            const playing_episode = store.getters.playing_episode

            if (!(playing_episode == null || typeof playing_episode == 'undefined') && store.getters.is_playing) {
                isa.listening_podcast = playing_episode.podcast.id
                isa.podcast_time_in_seconds = store.getters.playing_episode_current_time
            }

            CampGeoApiService.createImageSwipeAction(isa)

        }
      const slides = ref();
      const setSwiperInstance = (swiper: any) => {
        slides.value = swiper;
        slides.value.autoHeight = true;
      }
      const slideChangeTransitionEnd = $event => {
        slider_moved_manually.value = false
      }
        return {
            modules: [Pagination, Scrollbar],
            setSwiperInstance,
            imageLoaded,
            sliderMove,
            snapIndexChange,
            onSwiper,
            transitionStart,
            showImageOverlay,
            slideChangeTransitionEnd,
            transitionEnd,
            transitioning
        }
    },
    components: {
        Swiper,
        SwiperSlide,
        PodcastMediaView,
    }
})
