import axios from "axios"
import jwt_decode from "jwt-decode";
import { useRouter } from "vue-router";
import { useStore } from "@/store";
import { ActionTypes } from "./action-types";

type InitialAuthenticationResponseData = {
    refresh: string,
    access: string
}

type AuthenticationResponseData = {
    access: string
}

export type LoginCredentials = {
    google_token?: string,
    facebook_token?: string,
    twitter_token?: string,
    twitter_verifier?: string,
    twitter_oauth_token? :string,
    twitter_oauth_token_secret?: string,
    google_code?: string,
    ad_origin?: string|null
}

export type RegisterCredentials = {
    username: string,
    password: string,
    confirm_password: string,
    email: string
}

export const VerifyRegisterCredentials = (register_credentials: RegisterCredentials): void  => {
    if(register_credentials.username.length <= 2) {
        throw "Username must be more than 2 characters"
    }
    if(register_credentials.password.length < 8) {
        throw "Password must be at least 8 characters"
    }
    if(register_credentials.password != register_credentials.confirm_password) {
        throw "Passwords do not match"
    }
}

export type JWTDecode = {
    exp: number,
    iat?: number,
    token_type?: string,
    user_id: number
}

export default class CampGeoAuthentication {
    lc?: LoginCredentials

    refresh_expiration?: number
    access_expiration?: number
    user_id?: number

    constructor(login_credentials?: LoginCredentials) {
        this.lc = login_credentials
    }

    getExpiration(token: string): number {
        try {
            const decoded = jwt_decode<JWTDecode>(token);
            this.user_id = decoded.user_id
            return decoded.exp;
        }
        catch (e) {
            return 0;
        }
    }

    isExpired(expiration_date: number): boolean {
        const now = Date.now() / 1000;
        const expires_in = expiration_date - now;
        return expires_in <= 10
    }

    getUserId(): number | undefined {
        return this.user_id
    }

    getRefreshToken(): string | null {
        return localStorage.getItem('refresh_token');
    }

    setRefreshToken(refresh_token: string) {
        localStorage.setItem('refresh_token', refresh_token);
    }

    getAccessToken(): string | null {
        return localStorage.getItem('camp_geo_access_token');
    }

    setAccessToken(camp_geo_access_token: string) {
        return localStorage.setItem('camp_geo_access_token', camp_geo_access_token);
    }

    authenticationTokenIsValid(): boolean {
        if (this.access_expiration == null) {
            return false;
        }
        return !this.isExpired(this.access_expiration)
    }
    getAuthenticationToken(): Promise<string> {
        return new Promise((resolve, reject) => {
            const refresh_token = this.getRefreshToken();
            if (typeof refresh_token == "undefined" || refresh_token == null) {
                reject("No refresh token")
            }
            axios.post<AuthenticationResponseData>(`${process.env.VUE_APP_BACKEND_URL}/api/token/refresh/`, {
                refresh: refresh_token
            }).then(response => {
                const camp_geo_access_token = response.data.access
                this.setAccessToken(camp_geo_access_token);
                this.access_expiration = this.getExpiration(camp_geo_access_token);
                resolve(camp_geo_access_token)
            })
            .catch(e => {
                reject(e)
            })
        })
    }

    getInitialAuthenticationTokens(): Promise<void> {
        return new Promise((resolve, reject) => {
            axios.post<InitialAuthenticationResponseData>(`${process.env.VUE_APP_BACKEND_URL}/account/api/signin`, this.lc).then(response => {
                const refresh_token = response.data.refresh;
                this.setRefreshToken(refresh_token);
                this.refresh_expiration = this.getExpiration(refresh_token);
                this.getAuthenticationToken().then(() => {
                    resolve();
                });

            }).catch(error => {
                reject(error.response.data.detail);
            })
        })
    }

    isLoggedIn(): boolean {
        let refresh_token:string|null = null
        try {
            refresh_token = this.getRefreshToken()
        }
        catch(e) {
            const store = useStore()
            const router = useRouter()
            store.dispatch(ActionTypes.LOGOUT)
            router.push('/landing-page')
        }
        if (typeof refresh_token == "undefined" || refresh_token == null) {
            return false;
        }
        if (typeof this.refresh_expiration == "undefined" || this.refresh_expiration == null) {
            this.refresh_expiration = this.getExpiration(refresh_token);
        }
        if (typeof this.refresh_expiration != "number" || this.isExpired(this.refresh_expiration)) {
            return false;
        }

        return true;
    }
    removeAuthTokens(): void {
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("camp_geo_access_token");
    }
    removeUser(): void {
        this.user_id = undefined;
    }
}