
/*global FB*/
import PageTemplate from './PageTemplateLandingPage.vue';
import { defineComponent } from 'vue';
import LoginToolbar from './components/login-toolbar.vue';
import LoginContent from './components/login-content.vue';
import LandingPageContent from './components/landing-page-content.vue';
import LandingPageToolbar from './components/landing-page-toolbar.vue';
import GradientBackground from './components/gradient-background.vue';
import useLoginApi from "./hooks/login-api"
export default defineComponent({
    name: "LoginPage",
  setup(props, { emit }) {
    return {
      ...useLoginApi()
    }
  },
  components: {
    PageTemplate,
    LoginContent,
    LoginToolbar,
    LandingPageContent,
    LandingPageToolbar,
    GradientBackground,
  },
});
