
import { Episode } from "@/data/episodes";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";
import { computed, defineComponent, nextTick, ref, watch, WritableComputedRef, onMounted } from "vue";
import { tenForward, tenBackward, skipBack, skipForward } from "../icons/carbon_icons/carbon_icons"
import {IonRange, IonGrid, IonButton, IonIcon, IonCol, IonRow, IonSelect, IonSelectOption} from "@ionic/vue"
import {secondsToString} from "../hooks/seconds-to-string"
import { playCircle, pauseCircle, playSkipForward, playSkipBack} from "ionicons/icons";
import { CampGeoApiService } from "@/services/CampGeoApiService";
import { AudioEventBus } from '@/event-bus/AudioPlayerEventBus';
import { AudioPlayerEvents } from "@/event-bus/AudioPlayerEvents";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
    name: 'LargeAudioPlayer',
    props: {
        episode: {
            type: Object as () => Episode,
            required: false,
            default: null,
            definition: "mp3 url"
        },
    },
    components: {
        IonRange, 
        IonGrid,
        IonButton,
        IonIcon,
        IonRow,
        IonCol,
        IonSelect,
        IonSelectOption
    },
    setup(props, context) {
        const mouse_down = ref<boolean>(false);
        
        const duration = computed(() => {
            
            if(props.episode) {
                return props.episode.podcast.duration
            }
            return 0;
        })

        const pinFormatter = (value: number) => {
            return secondsToString(value);
        }

        const mouseup = (CustomEvent: any) => {
            store.dispatch(ActionTypes.CHANGE_CURRENT_TIME, CustomEvent.detail.value)
            mouse_down.value = false;
        }

        const mousedown = () => {
            mouse_down.value = true;
        }
        const duration_label = computed(() => {
            return secondsToString(duration.value)
        })

        const audio_playback_speed: WritableComputedRef<number> = computed({
            get(): number {
                return store.getters.audio_playback_speed;
            },
            set(newVal: number) {
                AudioEventBus.getInstance().dispatch(AudioPlayerEvents.SET_AUDIO_PLAYBACK_SPEED, newVal)
            }
        })

        const audioPlaybackChange = (newVal: CustomEvent) => {
            audio_playback_speed.value = newVal.detail.value
        }

        const audio_playback_options = ref<number[]>([
            0.5,
            0.75,
            1,
            1.25,
            1.5,
            1.75,
            2
        ])
        const customAlertOptions: any = {
            header: 'Playback Rate',
            translucent: true
        };

        const store = useStore();
        

        const current_time = ref<number>(0);
        const store_current_time = computed<number>(() => {
            if(typeof store.getters.playing_episode_current_time != "undefined") {
                return store.getters.playing_episode_current_time
            }
            return 0;
        })

        watch(store_current_time, (newVal: number) => {
            if(mouse_down.value == false) {
                current_time.value = newVal;
            }
        })

        const current_time_label = computed(() => {
            return secondsToString(current_time.value);
        })

        const end_time = computed(() => {
            return secondsToString(duration.value)
        })

        const playing_mp3_url = computed(() => {
            return store.getters.playing_mp3_url;
        })

        const playing_episode = computed(() => {
            return store.getters.playing_episode
        })
        
        const is_playing = computed(() => {
            return store.getters.is_playing && playing_episode.value && props.episode && playing_episode.value.id == props.episode.id;
        })

        const is_playing_this_episode = computed(() => {
            if (typeof store.getters.playing_episode != 'undefined' && typeof props.episode != "undefined" && props.episode != null) {
                return props.episode.id == store.getters.playing_episode.id 
            } 
            return false;
        })

        const router = useRouter()
        const route = useRoute()

        const playButtonClick = () => {
            if(localStorage.getItem('preview') === 'true') {
                localStorage.setItem('postLoginRedirect', route.path);

                localStorage.removeItem('preview')

                router.push({path: '/landing-page', query: {
                    redirect: route.path
                }})
                return;
            }
            if(playing_mp3_url.value != props.episode.podcast.mp3_url) {
                store.dispatch(ActionTypes.SET_PLAYING_EPISODE, props.episode);
                nextTick(() => {
                    store.dispatch(ActionTypes.START_PLAYING)
                })
            } else {
                store.dispatch(ActionTypes.TOGGLE_AUDIO_PLAY);
            }
        };

        const goBackwardTenSecondsClick = () => {
            store.dispatch(ActionTypes.GO_BACKWARD_10_SECONDS);
        }

        const goForwardTenSecondsClick = () => {
            store.dispatch(ActionTypes.GO_FORWARD_10_SECONDS);
        }

        const skipFowardClick = () => {
            if(playing_episode.value && playing_episode.value.next_episode_id) {
                store.dispatch(ActionTypes.PAUSE_AUDIO)
                context.emit('setLoading', true)
                CampGeoApiService.getEpisode(playing_episode.value.next_episode_id).then(e => {                    
                    context.emit('changeEpisode', e)
                    store.dispatch(ActionTypes.SET_PLAYING_EPISODE, e)
            })
            .finally(() => {
                context.emit('setLoading', false)
            })

            }
        }

        const play_icon = computed(() => is_playing.value ? pauseCircle : playCircle)
        const mode = ref<string>("md");
        onMounted(() => {
            //eslint-disable-next-line
            const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
            //eslint-disable-next-line
            const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
            // if(iOS || isSafari) {
                mode.value = "ios";
            // }
        })


        return {
            mode,
            playButtonClick,
            play_icon,
            goForwardTenSecondsClick,
            goBackwardTenSecondsClick,
            skipFowardClick,
            tenBackward,
            tenForward, 
            skipBack,
            skipForward,
            playing_mp3_url,
            is_playing,
            current_time_label,
            current_time,
            end_time,
            duration,
            duration_label,
            playSkipForward,
            playSkipBack,
            mousedown,
            mouseup,
            mouse_down,
            pinFormatter,
            is_playing_this_episode,
            audio_playback_speed,
            audioPlaybackChange,
            audio_playback_options,
            customAlertOptions
        }
    }  
    

})
