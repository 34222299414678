
import { defineComponent } from "vue"
import { PodcastMedia } from '@/data/episodes';
export default defineComponent({
    name: 'podcast-media-transcript-item',
    props: {
        podcastMedia: {
            type: Object as () => PodcastMedia,
            required: true,
            description: "Podcast Media Item to be shown"
        }
    }
})
