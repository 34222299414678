
import PageTemplate from './PageTemplate.vue';
import { defineComponent, ref } from 'vue';
import { ActionTypes } from '@/store/action-types';
import { RegisterCredentials } from "@/store/camp-geo-authentication"
import { IonInput, IonCard, IonLabel, IonButton } from '@ionic/vue';
import { useStore } from '@/store';
import { useRouter } from 'vue-router';
export default defineComponent({
    name: "RegisterPage",
    components: {
      IonInput,
      IonLabel,
      IonCard,
      IonButton,
      PageTemplate
    },
    setup() {
      const store = useStore()
      const router = useRouter()
      
      const username = ref<string>("")
      const password = ref<string>("")
      const confirm_password = ref<string>("")
      const email = ref<string>("")
  
    
      const logging_in = ref(false)

      const registerUser = () => {
        logging_in.value = true;

        const rc: RegisterCredentials = {
          username: username.value,
          password: password.value,
          confirm_password: confirm_password.value,
          email: email.value
        }
        store.dispatch(ActionTypes.REGISTER_USER, rc)
        .then(() => {
          router.push('/')
        })
        .catch(error => {
          logging_in.value = false
          if(typeof error == "string") {
            alert(error);
          }
          if(typeof error == "object") {
            alert("An error has occurred");
          }
          console.error(error)
        })
        
      }

      return {
        username,
        email,
        password,
        confirm_password,
        registerUser,
        logging_in
      }
    }
})
