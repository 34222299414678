import {  computed, defineComponent, ref, onMounted, nextTick } from "vue";
import { Episode } from '../data/episodes';
import { useRouter, useRoute } from 'vue-router';
import { IonContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonButton, IonIcon } from '@ionic/vue';
import LargeAudioPlayer  from "../components/LargeAudioPlayer.vue"
import { chevronForward, chevronBack } from "ionicons/icons";
import PageTemplate from "./PageTemplate.vue"
import { CampGeoApiService } from "@/services/CampGeoApiService";
import PodcastMediaSlideshow from "@/components/PodcastMediaSlideshow.vue";
import ImageOverlay from "@/components/ImageOverlay.vue";
import EpisodeTranscript from "@/components/EpisodePage/EpisodeTranscript.vue"
import 'swiper/css/pagination';

export default defineComponent({
    name: "ViewEpisodePlayer",   
    data:() => ({
      chevronForward,
      chevronBack
    }), 
    setup(props, context) {
      const images_loaded = ref<boolean>(false)
      const imagesLoaded = () => {
        images_loaded.value = true
      }
        const router = useRouter();
        const route = useRoute();
        const episode = ref<Episode | undefined>(undefined);
        const ready = ref(false)
        const loading = ref(false)
        const setLoading = (new_loading_value: boolean) => {
          loading.value = new_loading_value
        }
        const setEpisode = (e: Episode) => {
          ready.value = false
          episode.value = e;

          nextTick(() => {
            ready.value = true
            if(episode.value && episode.value.podcast.media.length == 0) {
              images_loaded.value = true
            } else {

            setTimeout(() => {
              images_loaded.value = true
            }, 5000)
            }
          })
        }
        onMounted(() => {

        CampGeoApiService.getEpisode(parseInt(route.params.id as string, 10)).then(e => {
          setEpisode(e)
        })

        .catch((error) => {
          router.back()
        });
        })

        const changeEpisode = (new_episode: Episode) => {
          images_loaded.value = false

          setEpisode(new_episode)
          router.replace(`/player/episode/${new_episode.id}`)
        }
        const page_template_title = computed(() => {
          return typeof episode.value == "undefined" ? "" : episode.value.title;
        })


        const show_transcript = ref<boolean>(false)

        const is_loading = computed(() => {
          return ready.value == false || images_loaded.value == false || loading.value == true
        })

        const overlay_image = ref<string>("")
        const show_overlay = ref<boolean>(false)

        const showImageOverlay = (new_overlay_image: string) => {
          overlay_image.value = new_overlay_image
          show_overlay.value = true
        }
        const endZoom = () => {
          show_overlay.value = false
        }
        return { 
          episode,
          page_template_title, 
          ready, 
          images_loaded, 
          imagesLoaded, 
          show_transcript, 
          changeEpisode, 
          setLoading, 
          is_loading, 
          overlay_image, 
          show_overlay,
          showImageOverlay,
          endZoom
        }
    },
    components: {
        LargeAudioPlayer,
        PageTemplate,
        PodcastMediaSlideshow,
        IonButton,
        EpisodeTranscript,
        IonIcon,
        ImageOverlay
    },
})
