
import { Settings } from '@/data/settings';
import { CampGeoApiService } from '@/services/CampGeoApiService';
import { useStore } from '@/store';
import { ActionTypes } from '@/store/action-types';
import { defineComponent, onMounted, ref } from 'vue';
import { IonButton } from '@ionic/vue';
  
  export default defineComponent({
    name: 'user-agreement',
    components: {
        IonButton
    },
    setup(props, context) {
        const store = useStore()
        const settings = ref<Settings|undefined>(undefined)
        const loading_settings = ref<boolean>(true)
        const getSettings = () => {
            CampGeoApiService.getSettings().then(incoming_settings => {
                settings.value = incoming_settings
                loading_settings.value = false
            })
        }
        const acceptUserAgreement = () => {
            const user = store.getters.user
            if(user) {
                user.user_profile.accepted_user_agreement = true

                CampGeoApiService.updateUserProfile(user.user_profile).then(() => {
                    store.dispatch(ActionTypes.SET_USER, user)
                    context.emit('userAcceptedAgreement')
                })
            }
        }

        onMounted(() => {
            getSettings()
        })
        return {
            loading_settings,
            settings,
            acceptUserAgreement
        }
    }
  })
  