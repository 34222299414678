    import { MutationTypes } from "./mutation-types";
import { State } from "./state"
import { MutationTree } from "vuex";
import { Episode } from "@/data/episodes";
import { AudioEventBus } from "@/event-bus/AudioPlayerEventBus";
import { AudioPlayerEvents } from "@/event-bus/AudioPlayerEvents";
import CampGeoAuthentication from "./camp-geo-authentication";
import { User } from "@/data/user";
import mixpanel from 'mixpanel-browser';
export type Mutations<s = State> = {
    [MutationTypes.SET_IS_PLAYING](state: s, payload: boolean): void,
    [MutationTypes.SET_PLAYING_MP3_URL](state: s, payload: Episode): void
    [MutationTypes.SET_PLAYING_EPISODE_CURRENT_TIME](state: s, payload: number): void,
    [MutationTypes.SET_CAMP_GEO_AUTHENTICATION](state: s, payload: CampGeoAuthentication): void
    [MutationTypes.SET_AUDIO_PLAYBACK_SPEED](state: s, payload: number): void
    [MutationTypes.SET_FACEBOOK_IS_LOADED](state: s, payload: boolean): void
    [MutationTypes.SET_USER](state: s, payload: User): void
    [MutationTypes.CLEAR_USER](state: s, payload?: any): void
    [MutationTypes.CLEAR_PLAYING_EPISODE](state: s): void
}

function identityMixpanelUser(user: User) {
    mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY, { debug: true });
    mixpanel.identify(user.user_profile.uuid);

    mixpanel.people.set({
        $email: user.email,
    });

    mixpanel.track('Logged in', user.email)
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationTypes.SET_IS_PLAYING](state, payload) {
        state.is_playing = payload;
    },
    [MutationTypes.SET_PLAYING_MP3_URL](state, payload) {
        let changing = false;
        if (state.playing_episode) {
            if (state.playing_episode.id != payload.id) {
                changing = true;
            }
        }
        mixpanel.track('Playing Episode', payload)
        state.playing_episode = payload;
        if (changing) {
            AudioEventBus.getInstance().dispatch(AudioPlayerEvents.PLAYING_EPISODE_CHANGED);
        }
    },
    [MutationTypes.SET_PLAYING_EPISODE_CURRENT_TIME](state, payload) {
        state.playing_episode_current_time = payload;
    },
    [MutationTypes.CLEAR_PLAYING_EPISODE](state) {
        state.playing_episode = undefined;
    },
    [MutationTypes.SET_CAMP_GEO_AUTHENTICATION](state, payload) {
        state.camp_geo_authentication = payload;
    },
    [MutationTypes.SET_AUDIO_PLAYBACK_SPEED](state, payload) {
        state.audio_playback_speed = payload;
    },
    [MutationTypes.SET_FACEBOOK_IS_LOADED](state, payload) {
        state.facebook_is_loaded = payload;
    },
    [MutationTypes.SET_USER](state, payload) {
        identityMixpanelUser(payload)
        state.user = payload
    },
    [MutationTypes.CLEAR_USER](state) {
        mixpanel.reset()
        state.user = undefined
    }
}