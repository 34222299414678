
  import { ActionTypes } from '@/store/action-types';
  import { LoginCredentials } from '@/store/camp-geo-authentication';
  import { defineComponent, nextTick, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router';
  import PageTemplate from './PageTemplate.vue';
import { logOut } from 'ionicons/icons';
  
  export default defineComponent({
    components: { PageTemplate },
      name: 'facebook-callback',
      setup() {
          onMounted(() => {
          const route = useRoute();
          const store = useStore();
          const router = useRouter();
          const length = "#access_token=".length

          const logout = () => {
            router.push('/landing-page')
          }
          
          const url_params = typeof route.hash == 'string' ? new URLSearchParams(route.hash) : undefined;
          if(typeof url_params === 'undefined') {
            logout()
            return;
          }
          const facebook_token = url_params.has('#access_token') ? url_params.get('#access_token') : url_params.get('access_token');
          if(facebook_token == null) {
            logout()
            return;
          }

          const ad_origin = localStorage.getItem('ad_origin')
          if(typeof facebook_token == 'undefined') {
            logout()
            return;
          }
          const lc: LoginCredentials = {
            facebook_token: facebook_token,
            ad_origin: ad_origin == "" ? null : ad_origin
          }
          
          
        store.dispatch(ActionTypes.LOGIN, lc)
        .then(() => {
          localStorage.removeItem('ad_origin')
  
          nextTick(() => {
            const postLoginRedirect = localStorage.getItem('postLoginRedirect');
            if(postLoginRedirect) {
              localStorage.removeItem('postLoginRedirect');
              router.push(postLoginRedirect)
            } else {
              router.push('/');
            }
          })
        })
        .catch((e: any) => {
          logout()
        })
  
      })
  }
  })
  