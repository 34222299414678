import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "transcript-slide p-4" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_podcast_media_transcript_item = _resolveComponent("podcast-media-transcript-item")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      modules: _ctx.modules,
      pagination: "",
      scrollbar: "",
      onSwiper: _ctx.setSwiperInstance,
      "slides-per-view": 'auto',
      height: 120,
      "space-between": 15
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.episode.podcast.media, (media) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, {
            key: media.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_podcast_media_transcript_item, { "podcast-media": media }, null, 8, ["podcast-media"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules", "onSwiper"]),
    (_ctx.episode.transcript && _ctx.episode.transcript.length > 0)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          innerHTML: _ctx.episode.transcript,
          class: "text-size--small-small-medium text-block episode-transcript rounded-lg p-4 mh-50vh overflow-auto"
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}