
export const secondsToString = (seconds: number) => {
    const numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
    let numminutes: number|string = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
    let numseconds: number|string = (((seconds % 31536000) % 86400) % 3600) % 60
    if(numseconds < 10) {
        numseconds = `0${numseconds}`;
    }
    if(numminutes > 0)
    if(numhours > 0) {
        if(numminutes < 10) {
            numminutes = `0${numminutes}`
        }
        return `${numhours}:${numminutes}:${numseconds}`
    }
    return `${numminutes}:${numseconds}`;
}