import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { useStore } from "@/store"
interface RefreshToken {
    status: number;
    data: {
        hashToken: string;
    };
}

export abstract class HTTPBaseService {
    protected instance: AxiosInstance;
    protected token?: string;
    protected readonly baseURL: string;
    protected preview: boolean;

    public constructor(baseURL: string, token?: string, preview = true) {
        this.baseURL = baseURL;
        this.instance = axios.create({
            baseURL
        });
        this.token = token;
        this.preview = preview;

        this.initializeRequestInterceptor();
    }

    private initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this.handleRequest);
    };


    private handleRequest = (config: AxiosRequestConfig) => {
        if (this.preview == false && config && config.headers) {
            config.headers['Authorization'] = `Bearer ${this.token}`;
        }
        return config;
    };

    private handleError = async (error: AxiosError) => {
        const originalRequest = error.config;
        if (error.response?.status === 401) {
            const refreshToken = await this.refreshToken();
            if (refreshToken.status === 200) {
                this.token = refreshToken.data.hashToken;
                return this.instance(originalRequest);
            }
        }
    }

    private async refreshToken(): Promise<RefreshToken> {
        const store = useStore()
        const cga = store.getters.camp_geo_authentication;
        if (cga == null) {
            throw "CGA Not setup";
        }
        const refreshTokenRequest = {
            hashToken: this.token
        };

        const { data } = await this.addRequestOptionsForClientSecrect();
        const options: AxiosRequestConfig = {
            headers: {
                CLIENT_KEY: data.clientKey
            }
        };

        return axios.post(`
        ${this.baseURL}/User/RenewToken`, refreshTokenRequest, options);
    }

    private addRequestOptionsForClientSecrect() {
        return axios.get(`${this.baseURL}/Utility/GetSecrets`);
    }
}