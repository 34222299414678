
import router from '@/router';
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import patentPendingFooter from './patent-pending-footer.vue';
import { IonCard, IonToolbar, IonButton, IonIcon , IonTitle} from '@ionic/vue';

export default defineComponent({
  components: { patentPendingFooter, IonButton },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
            description: "If the page is loading"
        },
        showGoogle: {
          type: Boolean,
          required: false
        },
        title: {
          type: String,
          required: false,
          default: "Welcome back!"
        },
        subtitle: {
          type: String,
          required: false,
          default: "Log in to keep learning"
        },
        loginLeadInText: {
          type: String,
          required: false,
          default: "Log in With"
        },
        actionableDescription: {
          type: String,
          required: false,
          default: "New User?"
        },
        actionableButtonText: {
          type: String,
          required: false,
          default: "Sign Up"
        },
        actionableButtonLink: {
          type: String,
          required: false,
          default: "/signup"
        },
        showExcerpt: {
          type: Boolean,
          required: false,
          default: false,
          description: "Show description"
        }
    },
    setup(props, {emit}) {
      const route = useRoute()
        const googleCallback = $event => {
            emit('googleCallback', $event);
        }
        const twitterCallback = $event => {
            emit('twitterCallback', $event);
        }
        const facebookCallback = $event => {
            emit('facebookCallback', $event);
        }

        const goToActionableLink = () => {
          router.push({path: props.actionableButtonLink, query: route.query})
        }

        return {
            googleCallback,
            twitterCallback,
            facebookCallback,
            goToActionableLink
        }
        
    },
    
})
