import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "large-course-card p-20" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = { class: "flex flex-col pl-5" }
const _hoisted_4 = { class: "m-0 large-course-card-title bold" }
const _hoisted_5 = { class: "my-5 course-summary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(), _createBlock(_component_inline_svg, {
          key: _ctx.random_number,
          class: "course-list-item-icon w-22vw mh-96 p-5",
          style: {"text-align":"center"},
          src: _ctx.icon_url,
          "aria-label": "My image"
        }, null, 8, ["src"]))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.course.title), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.course.summary), 1)
      ])
    ])
  ]))
}