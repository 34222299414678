
import { Chapter, getIconUrl } from '@/data/chapters';
import { IonIcon, IonItem, IonLabel, IonCol, IonRow, IonGrid} from '@ionic/vue';
import { chevronForward } from 'ionicons/icons';
import { defineComponent, ref, onMounted } from 'vue';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'ChapterListItem',
  components: {
    IonItem,
    IonCol,
    IonRow,
    IonIcon,
    IonLabel,
    IonGrid,
    InlineSvg
  },
  props: {
    chapter: Object as () => Chapter,
    preview: Boolean
  },
  setup(props, context) {
    
    const icon_url = ref<string>("");
    const random_number = ref<number>()
    onMounted(() => {
      if(props.chapter) {
        icon_url.value = getIconUrl(props.chapter);
        if(props.chapter.icon_url == null) {
          context.emit('svgLoaded')
        }
      }
      random_number.value = Math.random()

    })

    return {
      icon_url, random_number
    }
  },
  data() {
    return { 
      chevronForward,
      icon: null
     }
  },
  computed: {
  }
});
