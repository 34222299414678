
import { Chapter } from "./chapters";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
// title = models.CharField(max_length=50, help_text="Title of the Course")
// summary = models.TextField(blank=True, null=True)
// stripe_item_id = models.TextField(blank=True, null=True)
// published = models.BooleanField(default=False)
// slug = models.CharField(max_length=20, unique=True, )
// icon_file_url = models.TextField(blank=True, null=True)

// authors = models.ManyToManyField(Author, related_name="courses")
// publish_date=models.DateField(default=datetime.now, blank=True)
interface StripePrice {
    active: boolean,
    billing_scheme: string,
    created: number,
    currency: string,
    object: string
    product: string,
    unit_amount: number
}
interface StripeProduct {
    active: boolean,
    attributes: Array<any>,
    created: number,
    default_price: string,
    default_price_object?: StripePrice,
    description: string,
    id: string,
    images: Array<any>,
    livemode: boolean,
    name: string,
    object: string,
    package_dimensions: any,
    shippable: any,
    statement_descriptor: any, 
    tax_code: any,
    type: string,
    unit_label: any,
    updated: number,
    url: string,
}

export interface Course {
    id: number;
    chapter_number: number;
    title: string;
    summary: string;
    chapters?: Array<Chapter>;
    number_of_chapters: number,
    image_url: string,
    icon_file_url: any,
    light_icon_url: string,
    header_file_url?: string,
    slug: string|null,
    stripe_product: StripeProduct,
    is_free_course: boolean,
    author_string: string,
    stripe_payment_url?: string,
    duration_description: string
}

export const getIconUrl = (course: Course): string => {
    return getIconUrlFromCourseId(course.id)
}

export const getIconUrlFromCourseId = (course_id: number): string => {
    return `${process.env.VUE_APP_BACKEND_URL}/api/courses/icon/${course_id}`
}

export const getAmountString = (course: Course): string => {

    if(typeof course.stripe_product.default_price_object != 'undefined') {
        const amount = course.stripe_product.default_price_object.unit_amount
        return '$' + (amount / 100)
      }
      return ""
}

export const getCoursePageUrl = (course: Course): string => {
    return `/courses/${course.slug}` 
}
export const getCoursePaymentUrl = (course: Course): Promise<string> => {
    return new Promise((resolve, reject)=> {
        axios.get(`${process.env.VUE_APP_BACKEND_URL}/api/courses/${course.id}payment-url`)
        .then(response => {
            resolve(response.data.payment_url)
        })
        .catch(e => {
            reject(e.response.data.message);
        })
    })
    
}