import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-523b4b81"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chapter-list flex flex-col justify-space-between h-100 h-75" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = { class: "px-0" }
const _hoisted_4 = { class: "list-background-square" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChapterListItem = _resolveComponent("ChapterListItem")!
  const _component_ion_list = _resolveComponent("ion-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_list, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visible_chapters, (chapter) => {
                  return (_openBlock(), _createBlock(_component_ChapterListItem, {
                    key: chapter.id,
                    chapter: chapter,
                    onSvgLoaded: _ctx.svgLoaded
                  }, null, 8, ["chapter", "onSvgLoaded"]))
                }), 128))
              ]),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}