
    // Import Swiper Vue.js components
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { IonList, IonIcon, IonGrid, IonRow, IonCol, IonButton, IonContent } from '@ionic/vue';
    import { useLandingPageApi } from "../hooks/landing-page-api"

  
    // Import Swiper styles
    import 'swiper/css';
  
    export default {
      props: {
        loading: {}
      },
      components: {
        Swiper,
        SwiperSlide,
        IonRow,
        IonCol,
        IonGrid,
        IonButton,
        IonContent
      },
      setup() {
        return {
          ...useLandingPageApi()
        };
      },
    };
  