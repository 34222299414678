import { HTTPBaseService } from "./HTTPBaseService";
import { Chapter } from "@/data/chapters";
import { useStore } from "../store"
import { useRouter, useRoute } from "vue-router";
import { Episode } from "@/data/episodes";
import { ListenAction } from "@/event-bus/AudioPlayerEventBus";
import { ActionTypes } from "@/store/action-types";
import { User, UserProfile } from "@/data/user";
import { Settings } from "@/data/settings";
import { Course } from "@/data/courses";


export interface ImageZoomAction {
    created_at: Date,
    podcast_time_in_seconds?: number,
    listening_podcast?: number,
    listener: number,
    podcast_media: number
}

export interface ImageSwipeAction {
    created_at: Date,
    podcast_time_in_seconds?: number,
    listening_podcast?: number,
    listener: number,
    from_podcast_media: number,
    to_podcast_media: number,
    slide_automatically_moved?: boolean
}

export class CampGeoApiService extends HTTPBaseService {
    private static classInstance?: CampGeoApiService;

    constructor(token?: string) {
        const preview = localStorage.getItem('preview') == 'true';

        super(process.env.VUE_APP_BACKEND_URL, token, preview);
    }

    public static getInstance = async (): Promise<CampGeoApiService> => {
        const preview = localStorage.getItem('preview') == 'true';
        if(preview) {
            return new CampGeoApiService();
        }
        if (this.classInstance) {
            return this.classInstance;
        }
        const store = useStore()
        const cga = store.getters.camp_geo_authentication
        if (cga == null || typeof cga == "undefined" || !cga.isLoggedIn()) {
            const router = useRouter()
            const route = useRoute()
            router.push({path: 'landing-page', query: route.query})
            throw "No CGA"

        }
            return  await new Promise<CampGeoApiService>((resolve) => {
                cga.getAuthenticationToken().then(() => {
                    const at = cga.getAccessToken()
                    if (!at) {
                        const router = useRouter()
                        const route = useRoute()
                        router.push({path: '/landing-page', query: route.query})
                        return;
                    }
                    this.classInstance = new CampGeoApiService(at)
                    resolve(this.classInstance);
                })
                .catch(e => {
                    const store = useStore()
                    const router = useRouter()
    
                    store.dispatch(ActionTypes.LOGOUT)

                    const route = useRoute()
                    router.push({path: '/landing-page', query: route.query})
                    router.push('/landing-page')
                })
            })
    }
    
    public static getChapterList = async (id?: number): Promise<Array<Chapter>> => {
        const cgas_instance = await this.getInstance()
        const store = useStore()
        const router = useRouter();
        let url = `${process.env.VUE_APP_BACKEND_URL}/api/`
        
        if(cgas_instance.preview) {
            url += 'preview-chapter-list/'
        } else {
            url += 'chapter-list/'
        }

        if(id && id > 0) {
            url += `${id}/`
        }

        const { data } = await cgas_instance.instance.get(url);

        return data
    }

    public static getCourseList = async (): Promise<Array<Course>> => {
        const cgas_instance = await this.getInstance()
        let courses: Array<Course> = [];
        // try {
        const preview = localStorage.getItem('preview') == 'true';

        if(cgas_instance.preview) {
            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/preview-course-list/`);
            courses = data;
        } else {

            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/course-list/`);
            courses = data;
        }
        return courses;
    }

    public static getChapterById = async (id: number): Promise<Chapter> => {
        const cgas_instance = await this.getInstance()

        if(cgas_instance.preview) {
            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/preview-chapters/${id}/`);
            return data;
        } else {

            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/chapters/${id}/`);
            return data;
        }
    }

    public static getCourseBySlug = async (slug: string, only_preview = false): Promise<Course> => {
        const cgas_instance = await this.getInstance()

        if(cgas_instance.preview || only_preview) {
            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/preview-courses/${slug}/`);
            return data;
        } else {

            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/courses/${slug}/`);
            return data;
        }
    }

    public static getEpisode = async (id: number): Promise<Episode> => {
        const cgas_instance = await this.getInstance()
        if(cgas_instance.preview) {
            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/preview-episodes/${id}/`)
            return data;
        } else {

            const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/episodes/${id}/`)
            return data;
        }

    }

    public static createListenAction = async (listenAction:ListenAction): Promise<void> => {
        return new Promise((resolve, reject) => {
            this.getInstance().then(cgas_instance => {
                cgas_instance.instance.post(`${process.env.VUE_APP_BACKEND_URL}/api/listen-actions/`, listenAction)
                .finally(()=> {
                    resolve()
                })
            })
        })
    }

    public static removeClassInstance = (): void => {
        this.classInstance = undefined;
    }

    public static getUser = async (user_id: number): Promise<User> => {
        const cgas_instance = await this.getInstance()
        const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/users/${user_id}`)
        return data
    }

    public static getSettings = async (): Promise<Settings> => {
        const cgas_instance = await this.getInstance()
        const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/settings`)
        return data
    }

    public static updateUserProfile = async (user_profile: UserProfile): Promise<void> => {
        const cgas_instance = await this.getInstance()
        await cgas_instance.instance.put(`${process.env.VUE_APP_BACKEND_URL}/api/user-profiles/${user_profile.id}/`, user_profile)
    }

    public static createImageZoomAction = async (iza: ImageZoomAction): Promise<void> => {
        const cgas_instance = await this.getInstance()
        await cgas_instance.instance.post(`${process.env.VUE_APP_BACKEND_URL}/api/image-zoom-actions/`, iza)
    }

    public static createImageSwipeAction = async (isa: ImageSwipeAction): Promise<void> => {
        const cgas_instance = await this.getInstance()
        await cgas_instance.instance.post(`${process.env.VUE_APP_BACKEND_URL}/api/image-swipe-actions/`, isa)
    }

    public static getCoursePaymentUrl = async (course: Course): Promise<string> => {
        
        const cgas_instance = await this.getInstance()
        const { data } = await cgas_instance.instance.get(`${process.env.VUE_APP_BACKEND_URL}/api/courses/${course.id}/payment-url`)
        return data.payment_url;
        
    }
}