import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { tappable: "" }
const _hoisted_2 = { class: "justify-space-between flex h-0" }
const _hoisted_3 = { class: "text-size--small-medium color--darkteal" }
const _hoisted_4 = { class: "text-size--small-medium color--darkteal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_range = _resolveComponent("ion-range")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, { class: "audio-player-container" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "play-pause-control-row" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { class: "m-auto text-align-center p-0" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_ion_select, {
                          class: "text-size--large audio-speed-selector",
                          onIonChange: _ctx.audioPlaybackChange,
                          value: _ctx.audio_playback_speed,
                          "interface-options": _ctx.customAlertOptions,
                          interface: "popover",
                          icon: null,
                          mode: _ctx.mode
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.audio_playback_options, (item) => {
                              return (_openBlock(), _createBlock(_component_ion_select_option, {
                                key: item,
                                value: item,
                                mode: _ctx.mode
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item), 1)
                                ]),
                                _: 2
                              }, 1032, ["value", "mode"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["onIonChange", "value", "interface-options", "mode"])
                      ])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { class: "m-auto text-align-center p-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "m-0 icon-button text-size--large",
                        onClick: _ctx.goBackwardTenSecondsClick,
                        color: "dark",
                        fill: "white",
                        "icon-only": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.tenBackward,
                            style: {"stroke":"transparent"}
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { class: "m-auto text-align-center p-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "m-0 icon-button text-size--triple",
                        onClick: _ctx.playButtonClick,
                        color: "dark",
                        fill: "white",
                        "icon-only": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "svg-drop-shadow",
                            slot: "icon-only",
                            icon: _ctx.play_icon
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { class: "m-auto text-align-center p-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "m-0 icon-button text-size--large",
                        onClick: _ctx.goForwardTenSecondsClick,
                        color: "dark",
                        fill: "",
                        "icon-only": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.tenForward,
                            style: {"stroke":"transparent"}
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, { class: "m-auto text-align-center p-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        class: "m-0 icon-button text-size--large",
                        onClick: _ctx.skipFowardClick,
                        color: "dark",
                        fill: "white",
                        "icon-only": ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.playSkipForward
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, { size: "12 " }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.current_time_label), 1),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.duration_label), 1)
                      ]),
                      (_openBlock(), _createBlock(_component_ion_range, {
                        class: "range",
                        value: _ctx.current_time,
                        debounce: 4000,
                        pin: "",
                        key: _ctx.current_time,
                        pinFormatter: _ctx.pinFormatter,
                        min: 0,
                        max: _ctx.duration,
                        onIonKnobMoveStart: _ctx.mousedown,
                        onIonKnobMoveEnd: _ctx.mouseup
                      }, null, 8, ["value", "pinFormatter", "max", "onIonKnobMoveStart", "onIonKnobMoveEnd"]))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}