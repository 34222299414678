import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "wrapper m-auto" }
const _hoisted_2 = { class: "inner-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_podcast_media_view = _resolveComponent("podcast-media-view")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_swiper, {
        ref: "swiper",
        onInit: _ctx.onSwiper,
        onSlideChangeTransitionStart: _ctx.transitionStart,
        onSlideChangeTransitionEnd: _ctx.slideChangeTransitionEnd,
        onTouchStart: _ctx.transitionStart,
        modules: _ctx.modules,
        pagination: "",
        scrollbar: "",
        onSwiper: _ctx.setSwiperInstance,
        onTouchEnd: _ctx.transitionEnd,
        onSliderFirstMove: _ctx.sliderMove,
        onSnapIndexChange: _ctx.snapIndexChange
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mediaItems, (media, idx) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, {
              key: media.id
            }, {
              default: _withCtx(() => [
                _createVNode(_component_podcast_media_view, {
                  "podcast-media": media,
                  index: idx + 1,
                  "total-amount": _ctx.mediaItems.length,
                  onImageLoaded: _ctx.imageLoaded,
                  episode: _ctx.episode,
                  transitioning: _ctx.transitioning,
                  onShowImageOverlay: _ctx.showImageOverlay
                }, null, 8, ["podcast-media", "index", "total-amount", "onImageLoaded", "episode", "transitioning", "onShowImageOverlay"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onInit", "onSlideChangeTransitionStart", "onSlideChangeTransitionEnd", "onTouchStart", "modules", "onSwiper", "onTouchEnd", "onSliderFirstMove", "onSnapIndexChange"])
    ])
  ]))
}