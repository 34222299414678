import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from '@/store';

import { TwitterResponse } from "../../../TwitterAuth"
import axios, { AxiosResponse } from 'axios';
import { isSupportedBrowser } from '../resources/IsSupportedBrowser';
import { ActionTypes } from '@/store/action-types';

export default () => {


    const store = useStore()
    const user = ref({});

    const ad_origin = ref("")

    const twitterCallback = () => {
      location.href = `https://api.twitter.com/oauth/authenticate?oauth_token=${oauth_token.value}`
    }

    const goToGoodleUrl = () => {
      location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.VUE_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_GOOGLE_REDIRECT_URI}&scope=email&response_type=code`
    }

    const goToFacebookUrl = () => {
      const base_url = "https://www.facebook.com/v14.0/dialog/oauth"
      location.href = `${base_url}?client_id=${process.env.VUE_APP_FACEBOOK_APP_ID}&redirect_uri=${process.env.VUE_APP_FACEBOOK_REDIRECT_URI}&response_type=token`
    }
    
    const oauth_token = ref("")
    const loadTwitterAccount = () => {
      axios.get<TwitterResponse>(`${process.env.VUE_APP_BACKEND_URL}/account/api/twitter/request_token`)
      .then((response: AxiosResponse<TwitterResponse>)=> {
        localStorage.setItem('twitter_oauth_token', response.data.oauth_token)
        localStorage.setItem('twitter_oauth_token_secret', response.data.oauth_token_secret)
        oauth_token.value = response.data.oauth_token
      })
    }
    const setHasSeenLandingPage = () => {
        localStorage.setItem('has_seen_landing_page', 'true');
    }

      const is_supported_browser = ref<boolean>(false)

    onMounted(async () => {
      if(store.getters.is_playing || typeof store.getters.playing_episode != 'undefined') {
        store.dispatch(ActionTypes.CLEAR_PLAYING_EPISODE)
      }
      const route = useRoute();

      setHasSeenLandingPage()

      is_supported_browser.value = isSupportedBrowser()
      loadTwitterAccount()
      
      if(typeof route.query.from == 'string') {
        ad_origin.value = route.query.from
        localStorage.setItem('ad_origin', ad_origin.value)
      }
      if(typeof route.query.redirect === 'string') {
        localStorage.setItem('postLoginRedirect', route.query.redirect)
      }

    });

    const loading = ref(true)
    const logoLoaded = () => {
      loading.value = false
    }
  
    return {
      user, 
      twitterCallback,
      oauth_token,
      goToGoodleUrl,
      goToFacebookUrl,
      loading,
      logoLoaded,
      is_supported_browser

    }
}