import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b30e0788"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chapter-list-number-text" }
const _hoisted_2 = { class: "m-0 episode-title text--darkteal px-5" }
const _hoisted_3 = { class: "m-0 episode-description text--darkbrown px-5" }
const _hoisted_4 = {
  key: 0,
  class: "episode-duration text--darkbrown text-align-center bold"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.episode)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        routerLink: `/player/episode/${_ctx.episode.id}`,
        detail: false,
        class: "episode-list-item"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, { class: "episode-list-item-grid rounded" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "1",
                    class: "m-auto p-0"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.episode.episode_number), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "8",
                    class: "m-auto p-0"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.episode.title), 1),
                      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.episode.summary), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "2",
                    class: "m-auto"
                  }, {
                    default: _withCtx(() => [
                      (_ctx.episode.duration)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.episode_duration), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_col, {
                    size: "1",
                    class: "p-0 m-auto text-align-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        class: "episode-listen-icon m-auto v-align-middle",
                        icon: _ctx.listen_icon
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routerLink"]))
    : _createCommentVNode("", true)
}