<template>

  <div>

      <ion-menu v-if="show_menu_button" contentId="main-content"  side="end">
          <ion-list class="menu-list ">
              <ion-item class="text--darkteal bold text-size--double" >
                  <ion-label>
                      Menu
                  </ion-label>
              </ion-item>
              <ion-item class="text--white bold" :href="contact_us_url" target="_blank">
                  <ion-label>
                      Contact Us
                  </ion-label>
              </ion-item>
              <ion-item class="text--white bold" :href="terms_of_use_url" target="_blank">
                  <ion-label>
                      Terms of Use
                  </ion-label>
              </ion-item>
              <ion-item class="text--white bold" :href="privacy_policy_url" target="_blank">
                  <ion-label >
                      Terms of Use
                  </ion-label>
              </ion-item>
              <ion-item class="text--white bold" @click="logout">
                  <ion-label>Logout</ion-label>
              </ion-item>
          </ion-list>
      </ion-menu>
  <div id="main-content" :class="`ion-page page-template-wrapper mw-800  ${dark ? 'dark' : 'light'}`" :style="cssVars" main>
      <ion-menu-button v-if="show_menu_button" class="ion-menu-button text--darkteal" size="small"></ion-menu-button>  
      <template v-if="show_user_agreement_page == false">
      <div v-if="!noHeader" class="ion-header">
          <slot name="toolbar" :dark="dark">
              <ion-toolbar translucent class="page-template-toolbar large top-toolbar">
                  <slot name="start">
                      <ion-buttons slot="start" class="ion-buttons block">
                          <slot name="buttons-start">

                          <ion-button
                              v-if="!hideBackButton"
                              class="p-0 back-button letter-spacing-normal text-size--small-medium transform-none m-0 mh-24 block" 
                              :disabled="loading" @click="goBack">
                              {{ backButtonLabel }} 
                                  <ion-icon slot="start" class="bold md text-size--10 ml-2" :icon="chevronBack"></ion-icon>
                              </ion-button>

                              <!-- <ion-back-button class="p-0 back-button bold text-size--small transform-none block" :disabled="loading" v-if="show_back_button" :icon="chevronBack" :text="backButtonLabel" default-href="/"></ion-back-button> -->
                          </slot>
                      </ion-buttons>
                  </slot>
                  <ion-title class="p-0" size="small page-template-title bold">{{title}}</ion-title>
              
                  <ion-buttons slot="end" class="ion-buttons block">
                      <slot name="toolbar-end"></slot>
                      
                  </ion-buttons>
              </ion-toolbar>
          </slot>
    </div>
    <div :class="ion_content_class">

          <div class="gradient-background-wrapper">
              <img

              class="background-image-landing-page"
                  src="../../resources/YellowstoneCourseBackgroundDark.png" 
                  aria-label="My image" 
                  
              />
          </div>
        <transition name="fade" mode="out-in">

          <div :key="1" v-show="loading" class="h-100 logo-background z-100 p-8">
              <img v-if="dark" class="h-100 m-auto" src="../../resources/campgeo-logo.svg"/>  
              <img v-else class="h-100 m-auto" src="../../resources/campgeo-loading-light.svg"/>  
          </div>
          </transition>
          <slot name="content" :loading="loading">
          </slot>
    </div>
    <div v-if="show_ion_footer" class="ion-footer">
        <slot name="footer">
        </slot>

    </div>
    <slot name="overlay">
      </slot>
  </template>
  

  </div>
</div>


</template>

<script >
import { defineComponent } from "vue";
import { IonButtons, IonToolbar, IonTitle, IonThumbnail, IonButton, IonIcon, IonMenu, IonMenuButton, IonItem, IonLabel } from '@ionic/vue';
import { tenBackward} from "../icons/carbon_icons/carbon_icons"
import { chevronBack } from "ionicons/icons";
import { Transition, ref, onMounted } from "vue";
const campgeo_logo_light = "../../resources/campgeo-logo-light.svg"
const campgeo_logo_dark = "../../resources/campgeo-logo.svg"
// import GradientBackground from "./gradient-background.vue";
import { pause, play } from "@/icons/carbon_icons/carbon_icons";

export default defineComponent({
  name: 'PageTemplate',
  props: {
      title: {
          type: String,
          required: false,
          description: "Title of page to be shown in the header"
      },
      backButtonLabel: {
          type: String,
          required: false,
          default: null,
          description: "Label for back button"
      },
      hideBackButton: {
          type: Boolean,
          required: false,
          default: false,
          description: "Hide back button"
      },
      loading: {
          type: Boolean,
          required: false,
          default: false,
          description: "True if loading"
      },
      noHeader: {
          type: Boolean,
          required: false,
          default: false,
          description: 'turn off header'
      },
      defaultBackUrl: {
          type: String,
          required: false,
          default: null,
          description: "Default go back url"
      },
      ionContentClass: {
          type: String, 
          required: false,
          default: ""
      },
      noScrollingInContent: {
          type: Boolean,
          required: false,
          default: false,
          description: "add overflow hidden to content if true"
      },
      hideMenu: {
          type: Boolean,
          required: false,
          default: false,
          description: "Show menu"
      }
  },
components: {
  IonButtons,
  IonToolbar,
  IonTitle,
  IonButton,
  IonIcon,
  Transition,
  IonMenu,
  IonItem,
  IonLabel,
  IonMenuButton
},
  data() {
      return {
          getBackButtonText: () => {
              const win = window;
              const mode = win && win.Ionic && win.Ionic.mode;
              return mode === 'ios' ? 'Inbox' : '';
          },
          tenBackward,
          chevronBack,
          phoneViewportHeight: 0,
          dark: false, 
          aspectRatioFraction: 1, // Adjust the fraction as desired
      }
  },
  
  
  setup(props) {

        
      const contact_us_url = ref(process.env.VUE_APP_CONTACT_US_PAGE);
        const terms_of_use_url =  ref(process.env.VUE_APP_TERMS_OF_USE_URL);
        const privacy_policy_url =  ref(process.env.VUE_APP_PRIVACY_POLICY_URL);

      const play_icon = ref(play)

      const logout = () => {
          console.log('logout')
      }

      const playing_episode = ref({
          title: 'test',
          chapter_title: 'hi',

      });

      const playButtonClick = () => {
          console.log('play button click')
      };
      
      const goBackwardTenSecondsClick = () => {
          console.log('play backwards')
      }

      const icon_url = ref("");
      const random_number = ref();
      
      const show_user_agreement_page = ref(false)

      onMounted(() => {
          random_number.value = Math.random();
      })
      
      const goBack = () => {
          console.log('go back')
      }

      return {
          play_icon,
          playButtonClick,
          goBackwardTenSecondsClick,
          playing_episode,
          icon_url,
          random_number,
          goBack,
          show_user_agreement_page,
          contact_us_url,
          logout,
          terms_of_use_url,
          privacy_policy_url
      }
  },
  computed: {
      show_back_button() {
          return !this.is_home && !this.hideBackButton;
      },
      ion_content_class() {
          let ion_content_class =`ion-content ${this.ionContentClass} gradient-background`;
          if(this.noScrollingInContent) {
              ion_content_class += "overflow-hidden "
          }
          return ion_content_class 
      },  
      is_home() {
          return this.$route.path == '/';
      },
      show_ion_footer() {
          return this.playing_episode || this.$slots.footer;
      },
      body_color() {
          return this.dark ? 'black' : 'white'
      },  
      cssVars() {
          return {
              '--body-color': this.body_color,
          }
      },
      campgeo_logo_url() {
          if(this.dark) {
              return "../../resources/campgeo-logo.svg"
          }
          return "../../resources/campgeo-logo-light.png"
      },
      show_menu_button() {
          return this.hideMenu ? false : !this.loading && !this.show_user_agreement_page
      }
  },
  mounted() {
  this.calculateImagePosition();
  window.addEventListener('resize', this.calculateImagePosition);
},
beforeUnmount() {
  window.removeEventListener('resize', this.calculateImagePosition);
},
methods: {
  calculateImagePosition() {
    const container = this.$refs.container;
    const image = this.$refs.image;

    if (container && image) {
      const containerAspectRatio = container.offsetWidth / container.offsetHeight;
      const desiredHeight = (container.offsetWidth * this.aspectRatioFraction) / containerAspectRatio;
      const bottomPosition = -(desiredHeight - container.offsetHeight);
      image.style.bottom = `${bottomPosition}px`;
    }
  },
},
})



</script>

<style scoped>
  .page-template-wrapper {
      min-height: 100%;
      flex-direction: column;
      display: flex;
  }
  .ion-content {
      flex-grow: 1;
      overflow: scroll
  }
  .top-toolbar {
      max-height:24px;
  }
  ion-toolbar {
      /* color: white !important; */
      /* --ion-toolbar-background: black; */
      --min-height: 24px;
  }
  .small ion-buttons, .small .ion-buttons {
      max-height: 24px;
  }
  ion-button.back-button {
      --color: #666666;
  }

  ion-back-button {
      --padding-start: 0px;   
      --padding-end: 0px;
      --icon-font-size: 10px;
      min-width: 20px;
      max-height: 24px;
      --min-height: 100%;
      padding-inline: 0px;
  }

.fade-enter-active {
      transition: all 0.3s ease-out;
    }
    .fade-leave-active {
      transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }
    .fade-enter-from,
    .fade-leave-to {
      transform: translateX(20px);
      opacity: 0;
    }
    .ion-header {
      z-index: 2;
    }
    .ion-menu-button {
      z-index: 15;
      position: absolute;
      right: 0;
      font-size: 2.5rem;
      margin-right: 1rem;
      margin-top: 1rem;
    }
    .gradient-background {
      position:relative;
      background: linear-gradient(to bottom, #fefefe 33vh, #D6D6D6 50vh, #D6D6D6 66vh);
          background-repeat: no-repeat;
          background-size: 100%;
          background-attachment: local;
    }


.ion-menu-button {
      z-index: 15;
      position: absolute;
      right: 0;
      font-size: 3.5rem;
      margin-right: 1rem;
      margin-top: 1rem;
      --color: #ffffff;
    }
    ion-menu {
      --height: 1500px;
    }


.background-image-landing-page { 
  /* This controls the backround image */
  position: absolute;
  z-index: 0;
  /* bottom: -10vh; */
  width: 100vw;
  height: 100%;
  /* margin-top: 0vh; */
  opacity:1 ;
  left: 0;
  /* bottom: calc( 100vh / var(--aspect-ratio-fraction));  */

  /* bottom:calc(-10px - calc( 100vh - 600px )); */
  /* bottom: 0; */
}


.menu-list {
  background-image: linear-gradient(135deg, #ffffff,#ffffff,  #ffffff, #FAEDD3);
  /* background-image: linear-gradient(135deg, #425837 #B19780); */
  height:100%;
  padding-left:20px;
}


ion-list-header {
--background: transparent;
--color: #ffffff;
font-size: 2.0rem;
font-weight:bold;
}
ion-list-header.md{
  padding-left:40px;
}
ion-button[slot="start"] {
position: fixed;
top: 0;
left: 0;
z-index: 1;
}

ion-menu::part(backdrop) {

  background-color:#425837;
}

ion-menu::part(container) {
  border-radius: 20px 0px 0px 20px;
  box-shadow: -5px 0px 12px #425837;
}


.scroll-container {
position: absolute;
}

ion-content {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: 1;
overflow-y: auto;
/* Add any additional styling for your scroll box */
}
.fixed-background {
  background-image: backgound_icon_url; /* Replace with the correct path or URL to your PNG file */
background-size: cover;
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
z-index: -1;
/* Add additional background styles if needed */
}









</style>