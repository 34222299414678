
    import { useRoute, useRouter } from 'vue-router';
    import { information, musicalNote, search } from 'ionicons/icons';
    import { Course } from '../data/courses';
    import { computed, defineComponent, provide, ref, onMounted } from 'vue';
    import PageTemplate from './PageTemplate.vue';
    import LargeCourseCard from "../components/CoursePage/LargeCourseCard.vue"
    import CourseChapterList from "../components/CoursePage/CourseChapterList.vue"
    import { CampGeoApiService } from '@/services/CampGeoApiService';
    import ChapterListItem from '@/components/ChapterListItem.vue';
    import courseBanner from './components/course-banner.vue';
    import { useStore } from '@/store';
    export default defineComponent({
      data() {
        return {
          information,
          musicalNote,
          search,
          search_value: ""
        }
      },
      setup() {
        const route = useRoute();
        const router = useRouter();
        const course = ref<Course|null>();
        const ready = ref(false)
        const course_svg_loaded = ref<boolean>(false)
        const episode_svgs_loaded = ref<boolean>(false)

        const header_image = computed(() => {
          return typeof course.value?.header_file_url != 'undefined' && course.value?.header_file_url.length > 0 ? course.value?.header_file_url : null;
        })

        const svg_loaded = computed(() => {
          return course_svg_loaded.value && episode_svgs_loaded.value
        })


        const courseSvgLoaded = () => {
          course_svg_loaded.value = true
        }
        const timedout = ref<boolean>(false)
        onMounted(() => {
          setTimeout(() => {
            timedout.value = true;
          }, 5000)
        CampGeoApiService.getCourseBySlug(route.params.slug as string).then(c => {
          course.value = c;
          ready.value = true
        })
        .catch(e => {
          console.error(e)
          if(e.response.status == 403) {
            router.replace(`/preview-locked-courses/${route.params.slug as string}`)
          } else{

            CampGeoApiService.getCourseBySlug(route.params.slug as string).then(c => {
            course.value = c;
            ready.value = true
          })
          .catch(e => {
            console.log('error', e.response)
          })
          }
        });
        })
        provide('course', course);
        return { course, ready, course_svg_loaded, episode_svgs_loaded, courseSvgLoaded, timedout, header_image }
      },
      
      components: {
        PageTemplate,
        LargeCourseCard,
        CourseChapterList,
        ChapterListItem,
        courseBanner
    },
    });
    