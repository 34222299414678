
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import MainAudioPlayer from './components/MainAudioPlayer.vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    MainAudioPlayer
}
});
