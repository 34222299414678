
import { defineComponent, computed, ref, onMounted } from 'vue';
import { Chapter, getNextEpisodeFromChapter, getIconUrl } from "../../data/chapters"
import { IonButtons, IonRow, IonGrid, IonCol } from '@ionic/vue';
import { personCircle, shareOutline, play, ellipsisHorizontalOutline } from 'ionicons/icons';
import InlineSvg from 'vue-inline-svg';
import { ActionTypes } from '@/store/action-types';
export default defineComponent({
    props: {
        chapter: {
            type: Object as () => Chapter,
            required: true,
            description: "Chapter that is being viewed"
        }
    },
    components: {
      InlineSvg
    },
    data() {
      return {
        play,
        ellipsisHorizontalOutline
      }
    },
    setup(props, context) {
      const random_number = ref<number>()
      onMounted(() => {
        random_number.value = Math.random()
      });
      return {
        random_number
      }
    },  
    methods: {
      playChapter() {
        const episode = getNextEpisodeFromChapter(this.chapter);
        this.$store.dispatch(ActionTypes.SET_PLAYING_EPISODE, episode);
        if(!this.$store.getters.is_playing) {
          this.$store.dispatch(ActionTypes.SET_AUDIO_PLAYING);
        }
      }
    }
})
