
  import { ActionTypes } from '@/store/action-types';
  import { LoginCredentials } from '@/store/camp-geo-authentication';
  import { defineComponent, nextTick, onMounted, ref } from 'vue';
  import { useStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router';
  import PageTemplate from './PageTemplate.vue';
  
  export default defineComponent({
    components: { PageTemplate },
      name: 'google-callback',
      setup() {
        onMounted(() => {
            const route = useRoute();
            const store = useStore();
            const router = useRouter();
            const google_code = typeof route.query.code !== 'string'  ? undefined : route.query.code
            if(typeof google_code !== "string" || typeof google_code !== "string"){
                router.push('/landing-page')
                return;
            }
            const ad_origin = localStorage.getItem('ad_origin')
            const lc: LoginCredentials = {
                google_code: google_code,
                ad_origin: ad_origin == "" ? null : ad_origin

            }
          
        store.dispatch(ActionTypes.LOGIN, lc)
        .then(() => {
          localStorage.removeItem('ad_origin')
  
          nextTick(() => {
            const postLoginRedirect = localStorage.getItem('postLoginRedirect');
            if(postLoginRedirect) {
              localStorage.removeItem('postLoginRedirect');
              router.push(postLoginRedirect)
            } else {
              router.push('/');
            }
          })
        })
        .catch((e: any) => {
          router.push('/landing-page')
        })
  
      })
  }
  })
  