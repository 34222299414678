export enum MutationTypes {
    SET_IS_PLAYING = 'SET_IS_PLAYING',
    SET_PLAYING_MP3_URL = 'SET_PLAYING_MP3_URL',
    SET_PLAYING_EPISODE_CURRENT_TIME = 'SET_PLAYING_EPISODE_CURRENT_TIME',
    SET_CAMP_GEO_AUTHENTICATION = "SET_CAMP_GEO_AUTHENTICATION",
    SET_AUDIO_PLAYBACK_SPEED = "SET_AUDIO_PLAYBACK_SPEED",
    SET_FACEBOOK_IS_LOADED = "SET_FACEBOOK_IS_LOADED",
    SET_USER = "SET_USER",
    CLEAR_USER = "CLEAR_USER",
    CLEAR_PLAYING_EPISODE = "CLEAR_PLAYING_EPISODE"
}