import { AudioPlayerEvents } from "./AudioPlayerEvents";
import { IEventBus, Registry, Subscriber } from "./EventBus";

export enum AudioAction {
  PLAYED = 'PL',
  MOVED = 'MO',
  PAUSED = 'PA',
  STOPPED = 'ST',
  MEDIA_MOVED = 'MM',
  DISCONNECTED = 'DC',
  RECONNECTED = 'RC',
  STILL_PLAYING = 'SP',
  ALTERED_SPEED = 'AS'
}

export interface ListenAction {
  audio_action: AudioAction,
  created_at: Date,
  podcast_time_in_seconds: number,
  podcast: number,
  listener: number,
  playback_speed: number,
}

export class AudioEventBus implements IEventBus {
  private subscribers: Subscriber;
  private static nextId = 0;

  public dispatch<T>(event: AudioPlayerEvents, arg?: T): void {
    const subscriber = this.subscribers[event];
    if (subscriber === undefined) {
      return;
    }
    Object.keys(subscriber).forEach(key => subscriber[key](arg));
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  public register(event: AudioPlayerEvents, callback: Function): Registry {
    const id = this.getNextId();
    if (!this.subscribers[event]) this.subscribers[event] = {};

    this.subscribers[event][id] = callback;

    return {
      unregister: () => {
        delete this.subscribers[event][id];
        if (Object.keys(this.subscribers[event]).length === 0) {
          delete this.subscribers[event];
        }
      }
    }
  }

  private getNextId(): number {
    return AudioEventBus.nextId++;
  }
  
  private static instance?: AudioEventBus = undefined;

  private constructor() {
    // initialize attributes here.
    this.subscribers = {};

  }

  public static getInstance(): AudioEventBus {
    if (this.instance === undefined) {
      this.instance = new AudioEventBus();
    }

    return this.instance;
  }

}