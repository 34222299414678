
import { useLogoutApi } from '../resources/LogoutApi';
import { ref } from 'vue';
import { IonGrid, IonRow, IonCol } from '@ionic/vue';

export default {
    components: {
        IonRow,
        IonCol,
        IonGrid
    },
    setup() {

        const terms_of_use_url = ref(process.env.VUE_APP_TERMS_OF_USE_URL)
        const privacy_policy_url = ref(process.env.VUE_APP_PRIVACY_POLICY_URL)
        const use_logout_api = useLogoutApi()
        return {
            ...use_logout_api,
            terms_of_use_url,
            privacy_policy_url
        }
    }
}
