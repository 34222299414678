import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13118ac3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "landing-page-content flex flex-col h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_landing_page_slideshow = _resolveComponent("landing-page-slideshow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_landing_page_slideshow)
  ]))
}