
import { AudioEventBus, AudioAction, ListenAction } from "@/event-bus/AudioPlayerEventBus";
import { AudioPlayerEvents } from "@/event-bus/AudioPlayerEvents";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch, WritableComputedRef } from "vue";
import { CampGeoApiService } from '@/services/CampGeoApiService';
import _ from "lodash"
import { getLastLeftOff } from "@/data/episodes";
export default defineComponent({
    
    setup() {

        const store = useStore();
        const audioPlayer = ref<HTMLAudioElement>();
        const audioPlayerSet = ref(false);
        const disconnected = ref(false);

        const audio_loaded = ref(false)

        const createListenAction = async (audio_action: AudioAction, podcast_seconds_in_time: number) => {
            const playing_episode = store.getters.playing_episode
            if(typeof playing_episode === "undefined") {
                throw "No playing episode"
            }
            const listener = store.getters.user_id
            if(typeof listener == 'undefined') {
                throw "No active listener"
            }
            const la: ListenAction = {
                audio_action: audio_action,
                podcast_time_in_seconds: Math.floor(podcast_seconds_in_time),
                created_at: new Date(),
                podcast: playing_episode.podcast.id,
                listener: listener, 
                playback_speed: audio_playback_speed.value
            }
            await CampGeoApiService.createListenAction(la)
            if(audio_action == AudioAction.STOPPED) {
                store.dispatch(ActionTypes.CLEAR_PLAYING_EPISODE)
            }
        }

        const stopping = ref<boolean>(false)

        const is_playing: WritableComputedRef<boolean> = computed({
            get(): boolean {
                return store.getters.is_playing;
            },
            set(newVal: boolean) {
                store.dispatch(ActionTypes.SET_IS_PLAYING, newVal);
            }
        })
        const initial_set_of_audio_playback_speed = ref(false)
        const audio_playback_speed: WritableComputedRef<number> = computed({
            get(): number {
                return store.getters.audio_playback_speed
            },
            set(incoming_value: number) {
                
                if(audioPlayer.value){
                    audioPlayer.value.playbackRate = incoming_value
                }
                store.dispatch(ActionTypes.SET_AUDIO_PLAYBACK_SPEED, incoming_value)
                localStorage.setItem('audio_playback_speed', incoming_value.toString())

                if(initial_set_of_audio_playback_speed.value == false) {
                    initial_set_of_audio_playback_speed.value = true
                } else if (audioPlayer.value) {
                    createListenAction(AudioAction.ALTERED_SPEED, audioPlayer.value.currentTime)
                }
            }
        })

        const setEpisodePlayingCurrentTime = () => {
            if(audioPlayer.value) {
                store.dispatch(ActionTypes.SET_PLAYING_EPISODE_CURRENT_TIME, Math.floor(audioPlayer.value.currentTime))
            } 
        }

        const paused = async () => {
            is_playing.value = false;
            if(audioPlayer.value) {
                if(stopping.value) {
                    stopping.value = false
                    await createListenAction(AudioAction.STOPPED, audioPlayer.value.currentTime);
                } else {
                    await createListenAction(AudioAction.PAUSED, audioPlayer.value.currentTime);

                }
            }
            if(still_playing_interval) {
                await clearInterval(still_playing_interval)
            }
        }
        const interval = ref(process.env.VUE_APP_STILL_PLAYING_INTERVAL)
        const actual_interval = ref(20)
        const setActualInterval = () => {
            const p_actual_interval = interval.value > 20 ? interval.value : 20
            actual_interval.value = p_actual_interval * 1000
        }

        const played = async () => {
            is_playing.value = true;
            if(audioPlayer.value) {
                if(disconnected.value) {
                    createListenAction(AudioAction.RECONNECTED, audioPlayer.value.currentTime);
                } else {
                    createListenAction(AudioAction.PLAYED, audioPlayer.value.currentTime);
                }
                still_playing_interval = setInterval(setStillPlaying, actual_interval.value)
            }
        }

        const waiting = async () => {
            if(audioPlayer.value && is_playing.value && audioPlayer.value.currentTime > 0) {
                disconnected.value = true;
                await createListenAction(AudioAction.DISCONNECTED, audioPlayer.value.currentTime)
                if(still_playing_interval) {
                    clearInterval(still_playing_interval)
                }
            }
        }

        const seeked = async () => {
            if(audioPlayer.value) {
                let audio_action = AudioAction.MOVED
                if(change_current_time_with_media.value) {
                    audio_action = AudioAction.MEDIA_MOVED
                    change_current_time_with_media.value = false
                } 
                await createListenAction(audio_action, audioPlayer.value.currentTime)
            }
        }

        const ended = async () => {
            if(typeof store.getters.playing_episode != "undefined" && typeof store.getters.playing_episode.duration != "undefined") {
                await createListenAction(AudioAction.STOPPED, store.getters.playing_episode.duration)
            }
        }


        var t: number|undefined = undefined;
        var still_playing_interval: number|undefined = undefined;

        const setStillPlaying = _.debounce(async () => {
            if(audioPlayer.value && is_playing.value && still_playing_interval) {
                await createListenAction(AudioAction.STILL_PLAYING, audioPlayer.value.currentTime)
            } else if (still_playing_interval) {
                clearInterval(still_playing_interval)
            }
        }, (actual_interval.value * 0.8), {
            leading: true
        })

        watch(is_playing, value => {
            if(value) {
                setEpisodePlayingCurrentTime()
                t = setInterval(setEpisodePlayingCurrentTime, 500)
            } else {
                if(t) {
                    clearInterval(t);
                }
            }
        });

        const playing_mp3_url = computed(() => store.getters.playing_mp3_url)

        watch(playing_mp3_url, () => {
            audio_loaded.value = false
        })

        const toggleAudioPlay = () => {
            if(audioPlayer.value != null) {
                if(audioPlayer.value.paused) {
                    audioPlayer.value.play();
                } else {
                    audioPlayer.value.pause();
                    is_playing.value = false;
                }
            }
        }
        
        const pauseAudio = () => {
            if(audioPlayer.value != null) {
                if(is_playing.value) {
                    audioPlayer.value.pause()
                    is_playing.value = false
                }
            }
        }

        const stopAudio = () => {
            if(audioPlayer.value != null) {
                if(is_playing.value) {
                    stopping.value = true
                    audioPlayer.value.pause();
                    is_playing.value = false;
                }
            }
        }

        const playAudio = () => {
            if(audioPlayer.value != null) {
                if(is_playing.value == false) {
                    audioPlayer.value.play()
                    is_playing.value = true
                }
            }
        }

        const goBackwardTenSeconds = () => {
            if(audioPlayer.value) {
                audioPlayer.value.currentTime -= 10;
                setEpisodePlayingCurrentTime();
            }
        }

        const goForwardTenSeconds = () => {
            if(audioPlayer.value) {
                audioPlayer.value.currentTime += 10;
                setEpisodePlayingCurrentTime();
            }
        }

        const setAudioPlaying = () => {
            if(audioPlayer.value != null) {
                audioPlayer.value.play();
                is_playing.value = true;
            }
        }
        const change_current_time_with_media = ref(false)
        
        const changeCurrentTime = (newVal: number) => {
            if(audioPlayer.value) {
                audioPlayer.value.pause()
                audioPlayer.value.currentTime = newVal
                audioPlayer.value.play()
                setEpisodePlayingCurrentTime()
            }
        }

        const changeCurrentTimeWithMedia = (newVal: number) => {
            change_current_time_with_media.value = true
            changeCurrentTime(newVal)
        }
        const onloadeddata = () => {
            if(audioPlayer.value) {
                if(store.getters.playing_episode) {
                    audioPlayer.value.currentTime = getLastLeftOff(store.getters.playing_episode);
                }
                audioPlayer.value.playbackRate = audio_playback_speed.value

                audioPlayer.value.pause()
                nextTick(() => {
                    if(audioPlayer.value) {
                        audioPlayer.value.play()
                    }
                })
            }
            audio_loaded.value = true
        }
        
        const playingEpisodeChanged = () => {
            if(audioPlayer.value) {
                audioPlayer.value.load()
                audioPlayer.value.onloadeddata = onloadeddata
            }
        }

        const startPlaying = () => {
            if(store.getters.playing_episode) {
                if(audioPlayer.value) {
                    if(audio_loaded.value) {
                        audioPlayer.value.currentTime = getLastLeftOff(store.getters.playing_episode);
                        audioPlayer.value.play()
                    } else {

                        if(store.getters.playing_episode) {
                            audioPlayer.value.currentTime = getLastLeftOff(store.getters.playing_episode);
                        }
                        audioPlayer.value.load()
                        audioPlayer.value.pause()
                        audioPlayer.value.onloadeddata = onloadeddata
                    }
                }
            }
        }
        
        const setAudioPlaybackSpeed = (new_audio_playback_speed: number) => {
            audio_playback_speed.value = new_audio_playback_speed
        }
        
        onMounted(() => {
            AudioEventBus.getInstance().register(AudioPlayerEvents.TOGGLE_AUDIO_PLAY, toggleAudioPlay)
            AudioEventBus.getInstance().register(AudioPlayerEvents.PLAY_AUDIO, playAudio)
            AudioEventBus.getInstance().register(AudioPlayerEvents.PAUSE_AUDIO, pauseAudio)
            AudioEventBus.getInstance().register(AudioPlayerEvents.STOP_AUDIO, stopAudio)
            AudioEventBus.getInstance().register(AudioPlayerEvents.GO_FORWARD_10_SECONDS, goForwardTenSeconds)
            AudioEventBus.getInstance().register(AudioPlayerEvents.GO_BACKWARD_10_SECONDS, goBackwardTenSeconds)
            AudioEventBus.getInstance().register(AudioPlayerEvents.SET_AUDIO_PLAYING, setAudioPlaying)
            AudioEventBus.getInstance().register(AudioPlayerEvents.CHANGE_CURRENT_TIME, changeCurrentTime)
            AudioEventBus.getInstance().register(AudioPlayerEvents.CHANGE_CURRENT_TIME_WITH_MEDIA, changeCurrentTimeWithMedia)
            AudioEventBus.getInstance().register(AudioPlayerEvents.PLAYING_EPISODE_CHANGED, playingEpisodeChanged)
            AudioEventBus.getInstance().register(AudioPlayerEvents.START_PLAYING, startPlaying)
            AudioEventBus.getInstance().register(AudioPlayerEvents.SET_AUDIO_PLAYBACK_SPEED, setAudioPlaybackSpeed)

            // audioPlayer.setAttribute("onended","foo()");
            setActualInterval();
            window.addEventListener('beforeunload', async () => {
                if(is_playing.value && audioPlayer.value) {
                    await createListenAction(AudioAction.STOPPED, audioPlayer.value.currentTime)
                }
                })
                const saved_playback_speed = localStorage.getItem('audio_playback_speed');
                if(saved_playback_speed) {
                    audio_playback_speed.value = parseFloat(saved_playback_speed)
                } else {
                    audio_playback_speed.value = 1;
                }
            })
        return {
            audioPlayer,
            playing_mp3_url,
            paused,
            played,
            waiting,
            seeked,
            ended
        }
    },

});
