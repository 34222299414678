import { Episode } from "@/data/episodes";
import { User, UserProfile } from "@/data/user";
import CampGeoAuthentication from "./camp-geo-authentication";

export interface State {
    is_playing: boolean,
    playing_episode?: Episode,
    playing_episode_current_time: number,
    audio_playback_speed: number,
    facebook_is_loaded: boolean,
    user?: User,

    //Authentication
    camp_geo_authentication?: CampGeoAuthentication

}
export const state: State = {
    is_playing: false,
    playing_episode_current_time: 0,
    audio_playback_speed: 1,
    camp_geo_authentication: undefined,
    facebook_is_loaded: false,
    user: undefined
};