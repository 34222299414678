
import { Episode } from "./episodes";

export interface Chapter {
    id: number;
    chapter_number: number;
    title: string;
    description: string;
    episodes?: Array<Episode>;
    number_of_episodes: number,
    image_url: string,
    icon_url: any,
    light_icon_url: string
}

export const getNextEpisodeFromChapter = (chapter: Chapter): Episode => {
    if (chapter.episodes) {
        return chapter.episodes[0]
    }
    throw "Chapter does not have episodes"
};

export const getIconUrl = (chapter: Chapter): string => {
    return getIconUrlFromChapterId(chapter.id)
}

export const getIconUrlFromChapterId = (chapter_id: number): string => {
    return `${process.env.VUE_APP_BACKEND_URL}/api/chapters/icon/${chapter_id}`
}