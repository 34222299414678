
import { Episode } from '../data/episodes';
import { useStore } from "@/store";
import { PodcastMedia } from "@/data/episodes";
import { defineComponent } from "@vue/runtime-core";
import { IonButton, IonModal, IonRippleEffect,  IonCol, IonRow, IonGrid } from '@ionic/vue';
import { volumeHighOutline, timerOutline, expandOutline } from 'ionicons/icons';
import { ActionTypes } from "@/store/action-types";
import { computed, ref } from "vue"
import { CampGeoApiService, ImageZoomAction } from '@/services/CampGeoApiService';
export default defineComponent({
    props: {
        podcastMedia: {
            type: Object as () => PodcastMedia,
            required: true,
            description: "One media item in scroller"
        },
        index: {
            type: Number,
            required: true,
        },
        totalAmount:{
            type: Number,
            required: true
        },
        episode: {
            type: Object as () => Episode,
            required: true
        },
        transitioning: {
            type: Boolean,
            required: false,
            description: "True if transitioning in slideshow"
        }
    },
    components: {
        IonRippleEffect,
        IonCol,
        IonRow,
        IonButton,
        IonModal,
        IonGrid
    },
    setup(props, context) {
        const store = useStore()
        const image_zoomed = ref(false)
        const is_playing_this_episode = computed(() => {
            return store.getters.playing_episode && store.getters.playing_episode.id == props.episode.id
        })
        const goToTimeClick = () => {
            if(is_playing_this_episode.value) {
                store.dispatch(ActionTypes.CHANGE_CURRENT_TIME_WITH_MEDIA, props.podcastMedia.timestamp)
                if(!store.getters.is_playing) {
                    store.dispatch(ActionTypes.TOGGLE_AUDIO_PLAY)
                }
            }
        }

        const imageZoom = () => {
            context.emit('showImageOverlay', props.podcastMedia.image_url)
            const listener = store.getters.user_id
            if(typeof listener == "undefined") {
                throw "no active listener"
            }
            const pm_id = typeof props.podcastMedia == "undefined" || props.podcastMedia == null ? 0 : props.podcastMedia.id
            
            const playing_episode = store.getters.playing_episode
            const iza: ImageZoomAction = {
                created_at: new Date(),
                listener: listener,
                podcast_media: pm_id
            }

            if (!(playing_episode == null || typeof playing_episode == 'undefined') && store.getters.is_playing) {
                iza.listening_podcast = playing_episode.podcast.id
                iza.podcast_time_in_seconds = store.getters.playing_episode_current_time
            }

            CampGeoApiService.createImageZoomAction(iza)
            
            // image_zoomed.value = !image_zoomed.value
        }

        const modal_id = computed(() => {
            return `modal-id-${props.podcastMedia.id}`
        })
        return {
            goToTimeClick,
            is_playing_this_episode,
            image_zoomed,
            modal_id,
            imageZoom,
        }
    },
    data:() => ({
        volumeHighOutline,
        timerOutline
    }),
    methods: {
        imageLoaded() {
            this.$emit('imageLoaded');
        }
    }
})
