
  import { Course, getAmountString, getIconUrl } from '@/data/courses';
import { useStore } from '@/store';
  import { IonIcon, IonItem, IonLabel, IonCol, IonRow, IonGrid, IonCard } from '@ionic/vue';
  import { chevronForward, lockClosedOutline, lockOpenOutline } from 'ionicons/icons';
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import InlineSvg from 'vue-inline-svg';

  
  export default defineComponent({
    name: 'CourseListItem',
    components: {
      IonItem,
      IonCol,
      IonRow,
      IonIcon,
      IonLabel,
      IonGrid,
      InlineSvg,
      IonCard
    },
    props: {
      course: Object as () => Course,
    },
    setup(props, context) {
      const store = useStore()

      const course_is_unlocked = computed(() => {
        if(props.course?.is_free_course) {
          return true
        }

        const return_val = store.getters.user?.user_profile.unlocked_course_ids.filter(course_id => {
          return course_id == props.course?.id
        })
        return typeof return_val == 'undefined' ? false : return_val.length > 0
      })

      const icon_url = ref<string>("");
      const random_number = ref<number>()
      onMounted(() => {
        if(props.course) {
          icon_url.value = getIconUrl(props.course);
          if(props.course.icon_file_url == null) {
            context.emit('svgLoaded')
          }
        }
        random_number.value = Math.random()
      })

      const lock_icon = computed(() => {
        return props.course?.is_free_course ? lockOpenOutline : lockClosedOutline
      })

      const amount_string = computed(() => {
        if(props.course) {
          return getAmountString(props.course)
        }
        return null
      })

      const lock_icon_class = computed(() => {
        return `font-size-xxl ${course_is_unlocked.value ? '' : 'color-light-grey'}`
      })

      const DollarSignImageUrl = "/assets/icon/Dollarsign.png"
      const UnlockImageUrl = "/assets/icon/Unlock_icon.png"

      const lock_file_url = computed(() => {
        if(props.course && (props.course?.is_free_course || store.getters.user?.user_profile.unlocked_course_ids.includes(props.course.id))) {
          return UnlockImageUrl

        }
          return DollarSignImageUrl
      })
  
      return {
        icon_url, 
        random_number, 
        course_is_unlocked, 
        lock_icon,
        amount_string,
        lock_icon_class,
        lock_file_url
      }
    },
    data() {
      return { 
        chevronForward,
        icon: null
       }
    },
  });
  