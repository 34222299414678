
import { Chapter } from "@/data/chapters";
import { defineComponent, onMounted } from "@vue/runtime-core";
import EpisodeListItem from '@/components/EpisodeListItem.vue';
import { IonList } from "@ionic/vue"
import { computed, ref } from "vue"
import { useRouter } from "vue-router";
export default defineComponent({
    props: {
        searchValue: {
            type: String,
            required: false,
            default: "",
            description: "Searching in chapter"
        },
        chapter: {
            type: Object as () => Chapter, 
            required: true,
            description: "chapter"
        }
    },
    setup(props, context) {
        const router = useRouter();
        const visible_episodes = computed(() => {
            if(props.chapter && props.chapter.episodes) {
                if(props.searchValue.length > 0) {
                    return props.chapter.episodes.filter(e => {
                        return e.title.includes(props.searchValue)
                    })
                } else {
                    return props.chapter.episodes
                }
            }
            return [];
        })

        const svg_counter = ref<number>(0)
        const svg_loaded = ref<boolean>(false)
        const svgLoaded = () => {
            svg_counter.value++
            if(svg_counter.value == visible_episodes.value.length) {
                svg_loaded.value = true
                context.emit('svgLoaded')
            }
        }
        onMounted(() => {
            if(visible_episodes.value.length == 1) {
                router.replace(`/player/episode/${visible_episodes.value[0].id}`)
            }
        })

        return {
            visible_episodes,
            svgLoaded,
            svg_loaded
        }
    },
    components: {
        EpisodeListItem,
        IonList,
    }
})
