
  import {Pagination, Scrollbar, Navigation, Zoom,} from 'swiper';
import 'swiper/css';
import 'swiper/css/zoom'
import '@ionic/vue/css/ionic-swiper.css';
import { defineComponent, ref } from "vue";
import { closeOutline } from 'ionicons/icons';
import { IonContent, IonCardSubtitle, IonCardTitle, IonCardHeader, IonCard, IonButton, IonIcon } from '@ionic/vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
// https://github.com/coddicat/vue-pinch-scroll-zoom possible contender for a pinch zoom
export default defineComponent({
    name: 'image-overlay',
    props: {
        showOverlay: {
            type: Boolean,
            required: false,
            default: false,
            description: "Whether to show the overlay or not"
        },
        overlayImage: {
            type: String,
            required: false,
            default: "",
            description:"Image to show in the overlay"
        }
    },
    components: {
        IonIcon,
        IonButton,
        Swiper,
        SwiperSlide
    },  
    setup(props, context) {
        const endZoom = () => {
            context.emit("endZoom")
        }
        const slides = ref();
        const setSwiperInstance = (swiper: any) => {
            slides.value = swiper;
            slides.value.autoHeight = true;
        }
        const modules = ref([Zoom])
        return {
            endZoom,
            setSwiperInstance,
            modules
        }
    },  
    data:() => ({
        closeOutline,
    })
})
