
import { defineComponent, ref } from 'vue';
import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/vue';
import InlineSvg from 'vue-inline-svg';
import patentPendingFooter from './patent-pending-footer.vue';
import LandingPageSlideshow from './landing-page-slideshow.vue';
import { useLandingPageApi } from "../hooks/landing-page-api"

export default defineComponent({
    components: {
        IonButton,
        InlineSvg,
        patentPendingFooter,
        LandingPageSlideshow
    },
    setup() {

        const terms_of_use_url = ref(process.env.VUE_APP_TERMS_OF_USE_URL)
        const privacy_policy_url = ref(process.env.VUE_APP_PRIVACY_POLICY_URL)
        return {
            terms_of_use_url,
            privacy_policy_url,
            ...useLandingPageApi()
        }
    },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
            description: "When something is loaded"
        }
    },
    methods: {
        logoLoaded() {
            this.$emit('logoLoaded')
        },
    }
})
