
import InlineSvg from 'vue-inline-svg';
import { IonIcon, IonItem, IonLabel, IonCol, IonRow, IonGrid} from '@ionic/vue';
import helpCircleOutline from "../../src/icons/carbon_icons/help-circle-outline.svg"
import { onMounted, ref } from "vue"
export default {
    
  components: {
    IonItem,
    IonCol,
    IonRow,
    IonIcon,
    IonLabel,
    IonGrid,
    InlineSvg
  },
    setup(props, context) {
        const url = ref(process.env.VUE_APP_CONTACT_US_PAGE)
        const goToUrl = () => {
            window.open(url.value, '_blank');
        }
        return {
            helpCircleOutline,
            url,
            goToUrl
        }
    },
}
