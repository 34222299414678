import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("audio", {
    ref: "audioPlayer",
    preload: "metadata",
    type: "",
    "style-": "display:none",
    onPause: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.paused && _ctx.paused(...args))),
    onWaiting: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.waiting && _ctx.waiting(...args))),
    onSeeked: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.seeked && _ctx.seeked(...args))),
    onPlaying: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.played && _ctx.played(...args)))
  }, [
    (_ctx.playing_mp3_url)
      ? (_openBlock(), _createElementBlock("source", {
          key: 0,
          src: _ctx.playing_mp3_url,
          type: ""
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true)
  ], 544))
}