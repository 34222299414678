import { Episode } from "@/data/episodes";
import { User, UserProfile } from "@/data/user";
import { pause, play } from "@/icons/carbon_icons/carbon_icons";
import { VueElementConstructor } from "vue";
import { GetterTree } from "vuex";
import CampGeoAuthentication from "./camp-geo-authentication";
import { State } from "./state";

export type Getters = {
    is_playing(state: State): boolean,
    playing_episode(state: State): Episode | undefined,
    play_icon(state: State): VueElementConstructor,
    playing_mp3_url(state: State): string | undefined,
    playing_episode_current_time(state: State): number | undefined,
    user_id(state: State): number | undefined,
    //Authentication
    camp_geo_authentication(state: State): CampGeoAuthentication | undefined,
    is_logged_in(state: State): boolean,
    audio_playback_speed(state: State): number,
    facebook_is_loaded(state: State): boolean,
    user(state: State): User | undefined

}

export const getters: GetterTree<State, State> & Getters = {
    is_playing: (state => {
        return state.is_playing;
    }),
    playing_episode: (state => state.playing_episode),
    play_icon: (state => state.is_playing ? pause : play),
    playing_mp3_url: (state => state.playing_episode ? state.playing_episode.podcast.mp3_url : undefined),
    playing_episode_current_time: (state => state.playing_episode_current_time),
    user_id: (state => state.camp_geo_authentication?.user_id),
    //Authentication
    camp_geo_authentication: (state => state.camp_geo_authentication),
    is_logged_in: (state => {
        if (typeof state.camp_geo_authentication == "undefined") {
            return false;
        }
        return state.camp_geo_authentication.isLoggedIn()
    }),
    audio_playback_speed: (state => state.audio_playback_speed),
    facebook_is_loaded: (state => state.facebook_is_loaded),
    user: (state => state.user)
}