
export enum AudioPlayerEvents {
    TOGGLE_AUDIO_PLAY,
    GO_BACKWARD_10_SECONDS, 
    GO_FORWARD_10_SECONDS,
    SET_AUDIO_PLAYING,
    CHANGE_CURRENT_TIME,
    PLAYING_EPISODE_CHANGED,
    START_PLAYING,
    PLAY_AUDIO,
    PAUSE_AUDIO,
    SET_AUDIO_PLAYBACK_SPEED,
    CHANGE_CURRENT_TIME_WITH_MEDIA,
    STOP_AUDIO
}
