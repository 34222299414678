
import { defineComponent } from 'vue';

export default defineComponent({
    setup(props, {emit}) {
        const logoLoaded = () => {
            emit('logoLoaded')
        }
        return {
            logoLoaded
        }
    },
})
