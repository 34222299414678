import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_toolbar = _resolveComponent("login-toolbar")!
  const _component_login_content = _resolveComponent("login-content")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: "Login",
    "show-footer": "",
    "hide-back-button": "",
    "ion-content-class": "login-content"
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_login_toolbar, { onLogoLoaded: _ctx.logoLoaded }, null, 8, ["onLogoLoaded"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_login_content, {
        loading: _ctx.loading,
        onGoogleCallback: _ctx.goToGoodleUrl,
        onFacebookCallback: _ctx.goToFacebookUrl,
        onTwitterCallback: _ctx.twitterCallback,
        "actionable-description": "First time?",
        "show-google": _ctx.is_supported_browser
      }, null, 8, ["loading", "onGoogleCallback", "onFacebookCallback", "onTwitterCallback", "show-google"])
    ]),
    _: 1
  }))
}