import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55bc1252"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "course-title justify-space-between pl-4 pr-0" }
const _hoisted_3 = { class: "title text--darkteal" }
const _hoisted_4 = { class: "duration" }
const _hoisted_5 = { class: "m-0 episode-summary-course-list" }
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.course)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        routerLink: '/courses/' + _ctx.course.slug,
        detail: false,
        lines: "inset",
        class: "course-list-item text--dark-teal"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, { class: "course-list-item-grid rounded" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "3",
                        class: "icon-32 text-align-center p-0"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock("img", {
                            key: _ctx.random_number,
                            class: "course-list-item-icon icon-32",
                            style: {"text-align":"center"},
                            src: _ctx.course.icon_file_url,
                            "aria-label": "Course Icon",
                            onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('svgLoaded')))
                          }, null, 40, _hoisted_1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "5",
                        class: "ion-align-self-center course-title-column px-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.course.title), 1),
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.course.duration_description), 1)
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "3",
                        class: "icon-32 text-align-center p-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.course.summary), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "1",
                        class: "lock-icon p-4"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock("img", {
                            key: _ctx.random_number,
                            class: "course-list-item-icon",
                            style: {"text-align":"center"},
                            src: _ctx.lock_file_url,
                            "aria-label": "My image",
                            onLoaded: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('svgLoaded')))
                          }, null, 40, _hoisted_6))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routerLink"]))
    : _createCommentVNode("", true)
}