import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw, useRoute } from 'vue-router';
import CourseList from "../views/CourseList.vue"
import ChapterList from '../views/ChapterList.vue'
import ViewEpisodePage from "../views/ViewEpisodePage.vue";
import CoursePaymentConfirmation from "../views/CoursePaymentConfirmation.vue";
import ViewChapterPage from "../views/ViewChapterPage.vue";
import ViewCoursePage from "../views/ViewCoursePage.vue";
import PreviewLockedCourse from "../views/PreviewLockedCourse.vue";
import ViewEpisodePlayer from "../views/ViewEpisodePlayer.vue"
import LoginPage from "../views/LoginPage.vue"
import TwitterCallback from "../views/TwitterCallback.vue"
import GoogleCallback from "../views/GoogleCallback.vue"
import FacebookCallback from "../views/FacebookCallback.vue"
import RegisterPage from "../views/RegisterPage.vue"
import UnsupportedBrowser from "../views/UnsupportedBrowser.vue"
import LandingPage from "../views/LandingPage.vue"
import LandingPageYellowstone from "../views/LandingPageYellowstone.vue"
import SignupPage from "../views/SignupPage.vue"

import EpisodeAbout from "../components/EpisodePage/EpisodeAbout.vue"

import PodcastMediaItems from "../components/EpisodePage/PodcastMediaItems.vue"
import { useStore } from "@/store"
import { ActionTypes } from '@/store/action-types';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/landing-page',
    name: 'Landing Page',
    component: LandingPage
  },
  {
    path: '/ynp',
    name: 'Yellowstone Landing Page',
    component: LandingPageYellowstone
  },
  {
    path: '/',
    name: 'Courses',
    component: CourseList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/courses/:slug',
    name: 'Chapters In Course',
    component: ViewCoursePage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/preview-locked-courses/:slug',
    name: 'Locked Chapters In Course',
    component: PreviewLockedCourse,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/course-payment-confirmation',
    name: 'Course payment confirmation',
    component: CoursePaymentConfirmation
  },
  {
    path: '/chapters',
    name: 'Chapters',
    component: ChapterList,

    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: "Login",
    component: LoginPage,

    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupPage
  },
  {
    path: '/unsupported-browser',
    name: "UnsupportedBrowser",
    component: UnsupportedBrowser,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/register',
    name: "Register",
    component: RegisterPage,

    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/episode/:id',
    component: ViewEpisodePage,

    children: [
      {
        path: '',
        redirect: to => {
          return `/episode/${to.params.id}/about`
        }
      },
      {
        path: 'about',
        component: EpisodeAbout,

        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'media',
        component: PodcastMediaItems,

        meta: {
          requiresAuth: true
        }

      },
    ]
  },
  {
    name: 'ViewChapterPage',
    path: '/chapter/:id',
    component: ViewChapterPage,

    meta: {
      requiresAuth: true
    }

  },
  {
    path: '/player/episode/:id',
    component: ViewEpisodePlayer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path:'/twitter/callback',
    component: TwitterCallback
  },
  {
    path:'/google/callback',
    component: GoogleCallback
  },
  {
    path:'/facebook/callback',
    component: FacebookCallback
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from) => {
  (window as any).gtag('config', 'G-0NFGZK520P', {'page_path': to.path})

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const localStoragePreview = localStorage.getItem('preview') === 'true';
  const preview = to.query.preview === 'true'
  const store = useStore()
  store.dispatch(ActionTypes.SETUP_CAMP_GEO_AUTHENTICATION)
  if(preview == true) {
    if(store.getters.is_logged_in) {
      localStorage.removeItem('preview');
      return {
        path: to.path,
        query: {
          from: to.query.from
        }
      }
    }
    if(!requiresAuth) {
      return {
        path: '',
        query: {from: to.query.from, preview: 'true'}
      }
    }
  }
  else if (requiresAuth && !store.getters.is_logged_in && localStoragePreview != true) {
    return {
      path: '/landing-page',
      query: { redirect: to.path, from: to.query.from }
    }
  }
})
export default router
