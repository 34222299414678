
import { useRoute, useRouter } from 'vue-router';
import PodcastMediaSlideshow from "@/components/PodcastMediaSlideshow.vue";
import { information, image, play, ellipsisHorizontalOutline } from 'ionicons/icons';
import { Episode } from '../data/episodes';
import { defineComponent, ref } from 'vue';
import PageTemplate from './PageTemplate.vue';
import LargeAudioPlayer from "../components/LargeAudioPlayer.vue"
import { ActionTypes } from '@/store/action-types';
import { CampGeoApiService } from '@/services/CampGeoApiService';

export default defineComponent({
  data() {
    return {
      information,
      image,
      play,
      ellipsisHorizontalOutline
    }
  },
  setup() {
    const route = useRoute();
    const episode = ref<Episode|undefined>(undefined)
    
    CampGeoApiService.getEpisode(parseInt(route.params.id as string, 10)).then(data => {
      episode.value = data;
    });
    const router = useRouter()

    const changeEpisode = (new_episode: Episode) => {
      episode.value = new_episode
      router.push(`/player/episode/${new_episode.id}`)
    }
    return { episode, changeEpisode }
  },
  components: {
    PageTemplate,
    PodcastMediaSlideshow,
    LargeAudioPlayer
  },
  methods: {
    playEpisode() {
      this.$store.dispatch(ActionTypes.SET_PLAYING_EPISODE, this.episode)
      if(!this.$store.getters.is_playing) {
        this.$store.dispatch(ActionTypes.SET_AUDIO_PLAYING);
      }
    },
  }
});
