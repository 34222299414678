
import {  defineComponent, ref } from "vue";
  import {Pagination, Scrollbar, Navigation} from 'swiper';
import { Episode } from '../../data/episodes';
  import { Swiper, SwiperSlide } from 'swiper/vue';
import PodcastMediaTranscriptItem from "./PodcastMediaTranscriptItem.vue";

export default defineComponent({
    props: {
        episode: {
            type: Object as () => Episode,
            required: true,
            description: "Episode showing"
        }
    },
    components: {
    Swiper,
    SwiperSlide,
    PodcastMediaTranscriptItem
},  
    setup() {
        const slides = ref();
        const setSwiperInstance = (swiper: any) => {
            slides.value = swiper;
            slides.value.autoHeight = false;
        }
        return {
            modules: [Pagination, Scrollbar],
            setSwiperInstance
        }

    }
})
