import { AudioEventBus, ListenAction } from "@/event-bus/AudioPlayerEventBus";
import { ActionTypes } from "./action-types";
import { MutationTypes } from "./mutation-types";
import { ActionTree, ActionContext, Action } from 'vuex'
import { Mutations } from "./mutations";
import { State } from "vue";
import { AudioPlayerEvents } from "@/event-bus/AudioPlayerEvents";
import { Episode } from "@/data/episodes";
import CampGeoAuthentication, { LoginCredentials, RegisterCredentials, VerifyRegisterCredentials } from "./camp-geo-authentication";
import { useRouter } from "vue-router";
import axios from "axios";
import { getters } from "./getters";
import { CampGeoApiService } from "@/services/CampGeoApiService";
import { FacebookResponse, initFacebook, runFbSdk } from "../../FacebookAuth"
import { User } from "@/data/user";

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
    [ActionTypes.SET_IS_PLAYING](
        { commit }: AugmentedActionContext,
        payload: boolean
    ): void,
    [ActionTypes.TOGGLE_AUDIO_PLAY](): void,
    [ActionTypes.GO_BACKWARD_10_SECONDS](): void,
    [ActionTypes.GO_FORWARD_10_SECONDS](): void,
    [ActionTypes.SET_AUDIO_PLAYING](): void,
    [ActionTypes.PLAY_AUDIO](): void,
    [ActionTypes.PAUSE_AUDIO](): void,
    [ActionTypes.SET_PLAYING_EPISODE]({ commit }: AugmentedActionContext, payload: Episode): void,
    [ActionTypes.SET_PLAYING_EPISODE_CURRENT_TIME]({ commit }: AugmentedActionContext, payload: number): void
    [ActionTypes.CHANGE_CURRENT_TIME]({ commit }: AugmentedActionContext, payload: number): void,
    [ActionTypes.CHANGE_CURRENT_TIME_WITH_MEDIA]({ commit }: AugmentedActionContext, payload: number): void,
    [ActionTypes.LOGIN]({ commit }: AugmentedActionContext, payload: LoginCredentials): Promise<void>,
    [ActionTypes.SETUP_CAMP_GEO_AUTHENTICATION]({ commit }: AugmentedActionContext): void,
    [ActionTypes.LOGOUT](store: AugmentedActionContext): void
    [ActionTypes.REGISTER_USER]({ commit }: AugmentedActionContext, register_credentials: RegisterCredentials): Promise<void>
    [ActionTypes.START_PLAYING]({ commit }: AugmentedActionContext): void,
    [ActionTypes.SET_AUDIO_PLAYBACK_SPEED]({ commit }: AugmentedActionContext, payload: number): void
    [ActionTypes.SET_FACEBOOK_IS_LOADED]({commit}: AugmentedActionContext, facebook_is_loaded: boolean): void,
    [ActionTypes.SET_USER]({commit}: AugmentedActionContext, user: User): void,
    [ActionTypes.STOP_AUDIO]({commit}: AugmentedActionContext): void,
    [ActionTypes.CLEAR_PLAYING_EPISODE]({commit}: AugmentedActionContext): void
}

export const actions: ActionTree<State, State> & Actions = {
    [ActionTypes.CLEAR_PLAYING_EPISODE]({ commit }) {
        commit(MutationTypes.CLEAR_PLAYING_EPISODE, undefined)
    },
    [ActionTypes.STOP_AUDIO]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.STOP_AUDIO, undefined);
    },
    [ActionTypes.START_PLAYING]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.START_PLAYING);
    },
    [ActionTypes.TOGGLE_AUDIO_PLAY]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.TOGGLE_AUDIO_PLAY);
    },
    [ActionTypes.PAUSE_AUDIO]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.PLAY_AUDIO);
    },
    [ActionTypes.PLAY_AUDIO]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.PAUSE_AUDIO);
    },
    [ActionTypes.SET_AUDIO_PLAYING]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.SET_AUDIO_PLAYING);
    },
    [ActionTypes.SET_IS_PLAYING]({ commit }, payload) {
        commit(MutationTypes.SET_IS_PLAYING, payload);
    },
    [ActionTypes.GO_BACKWARD_10_SECONDS]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.GO_BACKWARD_10_SECONDS)
    },
    [ActionTypes.GO_FORWARD_10_SECONDS]() {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.GO_FORWARD_10_SECONDS)
    },
    [ActionTypes.SET_PLAYING_EPISODE]({ commit }, payload: Episode) {
        commit(MutationTypes.SET_PLAYING_MP3_URL, payload);
    },
    [ActionTypes.SET_PLAYING_EPISODE_CURRENT_TIME]({ commit }, payload: number) {
        commit(MutationTypes.SET_PLAYING_EPISODE_CURRENT_TIME, payload)
    },
    [ActionTypes.CHANGE_CURRENT_TIME]({ commit }, payload: number) {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.CHANGE_CURRENT_TIME, payload)
    },
    [ActionTypes.CHANGE_CURRENT_TIME_WITH_MEDIA]({ commit }, payload: number) {
        AudioEventBus.getInstance().dispatch(AudioPlayerEvents.CHANGE_CURRENT_TIME_WITH_MEDIA, payload)
    },
    [ActionTypes.SET_FACEBOOK_IS_LOADED]({ commit }, payload: boolean) {
        commit(MutationTypes.SET_FACEBOOK_IS_LOADED, payload)
    },
    [ActionTypes.LOGIN]({ commit }, payload: LoginCredentials): Promise<void> {
        const cga = new CampGeoAuthentication(payload);
        return new Promise<void>((resolve, reject) => {
            cga.getInitialAuthenticationTokens().then(() => {
                commit(MutationTypes.SET_CAMP_GEO_AUTHENTICATION, cga)
                resolve()
            })
            .catch(e => {
                reject(e)
            })
        })
    },
    [ActionTypes.SETUP_CAMP_GEO_AUTHENTICATION]({ commit, getters }): void {
        if (typeof getters.camp_geo_authentication == "undefined" || getters.camp_geo_authentication == null) {
            const cga = new CampGeoAuthentication();
            commit(MutationTypes.SET_CAMP_GEO_AUTHENTICATION, cga)
        }
    },
    [ActionTypes.LOGOUT](store): void {
        if(store.getters.is_playing) {
            AudioEventBus.getInstance().dispatch(AudioPlayerEvents.STOP_AUDIO);
        }
        if (typeof getters.camp_geo_authentication !== "undefined" && getters.camp_geo_authentication != null) {
            store.getters.camp_geo_authentication.removeAuthTokens();
            store.getters.camp_geo_authentication.removeUser();
            CampGeoApiService.removeClassInstance();
            store.commit(MutationTypes.CLEAR_USER, null)
            runFbSdk()
            window.fbAsyncInit = function() {
                initFacebook()

                window.FB.getLoginStatus(function(response: FacebookResponse) {
                    if (response.status === 'connected') {
                        window.FB.logout(function(response) {
                            //@ts-ignore
                        })
                    }
                })
            }
        }   
    },
    [ActionTypes.REGISTER_USER]({commit}, register_credentials): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                VerifyRegisterCredentials(register_credentials)
            } catch(error) {
                reject(error)
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/account/api/register`, register_credentials)
                .then(response => {
                    resolve()
                })
                .catch(error => {
                    reject(error)
                })
            })
    },
    [ActionTypes.SET_AUDIO_PLAYBACK_SPEED]({commit}, payload): void {
        commit(MutationTypes.SET_AUDIO_PLAYBACK_SPEED, payload)
    },
    [ActionTypes.SET_USER]({commit}, payload):void {
        commit(MutationTypes.SET_USER, payload)
    },
}