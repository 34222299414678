import { openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7790281a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "chapter-title-column justify-space-between pr-0" }
const _hoisted_3 = { class: "chapter-title-text" }
const _hoisted_4 = { class: "m-0 chapter-description-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_ctx.chapter)
    ? (_openBlock(), _createBlock(_component_ion_item, {
        key: 0,
        routerLink: _ctx.preview ? null : '/chapter/' + _ctx.chapter.id,
        detail: false,
        lines: "inset",
        class: "chapter-list-item"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_grid, { class: "chapter-list-item-grid rounded py-2" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, { class: "ion-align-items-center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        size: "3",
                        class: "chapter-icon text-align-center justify-content p-0"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(), _createElementBlock("img", {
                            key: _ctx.random_number,
                            class: "chapter-icon",
                            style: {"text-align":"center"},
                            src: _ctx.chapter.icon_url,
                            "display:": "",
                            "inline-block": "",
                            "aria-label": "My image",
                            onLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('svgLoaded')))
                          }, null, 40, _hoisted_1))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_col, {
                        size: "9",
                        class: "ion-align-self-center px-0"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _createElementVNode("div", null, [
                              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.chapter.title), 1),
                              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.chapter.description), 1)
                            ]),
                            _createVNode(_component_ion_row, { class: "text-size--duration number-of-episodes-text bold justify-content-end pr-2 h-full m-auto-0" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  class: "m-auto-0 go-to-episode-icon",
                                  icon: _ctx.ChapterArrow
                                }, null, 8, ["icon"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["routerLink"]))
    : _createCommentVNode("", true)
}