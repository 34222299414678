
  /*global FB*/
  import PageTemplate from './PageTemplateLandingPageYellowstone.vue';
  import { defineComponent, ref, onMounted } from 'vue';
  import LoginToolbar from './components/login-toolbar.vue';
  import LandingPageContent from './yellowstone/landing-page-content-ynp.vue';
  import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/css/zoom'
import { useRouter } from 'vue-router';
import GradientBackground from './components/gradient-background.vue';
  
export default defineComponent({
    setup() {
      const loading = ref(true)
      const router = useRouter()

      const logoLoaded = () => {
        loading.value = false
      }
        return {
            loading,
            logoLoaded
        }
    },
  components: {
    PageTemplate,
    LoginToolbar,
    LandingPageContent,
    GradientBackground,
  },
})
