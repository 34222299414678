
import { onMounted } from "vue"
import { useRoute } from 'vue-router'
export default {
    setup() {
        const route = useRoute()
        onMounted(() => {
            console.log('route', route.params)
        })
    }
}
