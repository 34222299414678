import { useRouter } from 'vue-router';
import { useStore } from "@/store";
import { ActionTypes } from '@/store/action-types';
import { computed } from 'vue';

export const useLogoutApi = () => {
    const store = useStore()
    const router = useRouter()
    const logout = () => {
        store.dispatch(ActionTypes.LOGOUT)
        localStorage.removeItem('preview')
        router.replace('/login')
    }

    const is_logged_in = computed(() => {
        return store.getters.is_logged_in
      })
    return {
        logout,
        is_logged_in
    }
}