import { openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "large-chapter-card p-20" }
const _hoisted_2 = { class: "flex flex-row" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "flex flex-col pl-5 m-auto-0" }
const _hoisted_5 = { class: "m-0 large-chapter-card-title bold" }
const _hoisted_6 = { class: "my-5 chapter-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        (_openBlock(), _createElementBlock("img", {
          key: _ctx.random_number,
          class: "chapter-list-item-icon w-22vw mh-96 p-5",
          style: {"text-align":"center"},
          src: _ctx.chapter.icon_url,
          "aria-label": "My image"
        }, null, 8, _hoisted_3))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.chapter.title), 1),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.chapter.description), 1)
      ])
    ])
  ]))
}