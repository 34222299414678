
  import { IonList, IonIcon, IonGrid, IonRow, IonCol } from '@ionic/vue';
  
  import { defineComponent, ref, computed, onMounted } from 'vue';
  import { CampGeoApiService } from '@/services/CampGeoApiService';
  import { Chapter } from '@/data/chapters';
  import ChapterListItem from '@/components/ChapterListItem.vue';
  import ContactUsListItem from "@/components/ContactUsListItem.vue"
  import PageTemplate from './PageTemplate.vue';
  import { settings, search } from 'ionicons/icons';
  import { useRouter } from 'vue-router';
  import { useStore } from "@/store";
  import { useLogoutApi } from './resources/LogoutApi';
  import logoutFooter from './components/logout-footer.vue';
  
  export default defineComponent({
    data:() => ({
      sort_order: 'newest',
      search_value: "",
      settings,
      search
    }),
    setup() {
      const use_logout_api = useLogoutApi()
      const svg_counter = ref<number>(0)
  
      const svgLoaded = () => {
        svg_counter.value++;
      }

    const store = useStore()
    const router = useRouter()

    const svg_loaded = computed<boolean>(() => {
        if(ready.value) {
          return svg_counter.value == chapters.value.length
        }
        return false
      })


    const ready = ref(false)
    const chapters = ref<Array<Chapter>>([]);
    const timedout = ref<boolean>(false)
  
  
      onMounted(() => {
        
      CampGeoApiService.getChapterList().then(data => {
        chapters.value = data;
        ready.value = true
      })
      .catch(e => {
        if(e.response.status == 401){
          //Unauthorized
          //Show error stating you are unauthorized to see this course
          router.push({name: 'Courses'})
        }
        else if(e.response.status == 403) {
          // There are multiple courses. Send them to the course list
          router.push({name: 'Courses'})
        }
        else {
          console.error(e)
          use_logout_api.logout()
        }
      })
      setTimeout(() => {
        timedout.value = true;
      }, 5000)
      })
  
      return {
        chapters,
        svg_loaded,
        svgLoaded,
        timedout,
        ready,
        svg_counter,
        ...use_logout_api
      }
    },
    methods: {
      refresh: (ev: CustomEvent) => {
        setTimeout(() => {
          ev.detail.complete();
        }, 3000);
      },
    },
    computed: {
      visible_chapters() {
        if(this.search_value.length > 0) {
          return this.chapters.filter(c => {
            return c.title.toLowerCase().includes(this.search_value.toLowerCase())
          })
        }
  
        return this.chapters;
  
      },
    },
    components: {
      IonList,
      ChapterListItem,
      PageTemplate,
      IonIcon,
      IonGrid,
      IonCol,
      ContactUsListItem,
      IonRow,
      logoutFooter
    },
  });
  