
import {
    IonList,
    IonIcon,
    IonGrid,
    IonRow,
    IonTitle,
    IonCol
} from '@ionic/vue';

import {
    defineComponent,
    ref,
    computed,
    onMounted
} from 'vue';
import {
    CampGeoApiService
} from '@/services/CampGeoApiService';
import {
    Course,
    getCoursePageUrl
} from '@/data/courses';
import CourseListItem from '@/components/CourseListItem.vue';
import ContactUsListItem from "@/components/ContactUsListItem.vue"
import PageTemplate from './PageTemplate.vue';
import {
    settings,
    search
} from 'ionicons/icons';
import {
    ActionTypes
} from '@/store/action-types';
import {
    useRouter
} from 'vue-router';
import {
    useStore
} from "@/store";
import {
    loadStripe
} from '@stripe/stripe-js';
// const stripe = require('stripe')('sk_test_51MQziuIL4XBrEF9qtihzstFPrr1StLq4UyzsIJaN8aFU2dE5GqWE5T5uRK3MHqrGwPlP9AqeEUWTtM0sXp2sOiU00008GhAbwa');

export default defineComponent({
    data: () => ({
        sort_order: 'newest',
        search_value: "",
        settings,
        search
    }),
    setup() {

        const setupStripe = async () => {
            const stripe = await loadStripe("pk_test_51MQziuIL4XBrEF9qA2Qc2NTlAtFxeo4BCudY4Y1XYLjgnJ96oPvzysW2WwUPKIJgfCi79lq9CCh5ZCzQdjQJl2se00YzOgtZqR")
        }

        const is_logged_in = computed(() => {
            return store.getters.is_logged_in
        })
        const svg_counter = ref < number > (0)

        const svgLoaded = () => {
            svg_counter.value++;
        }

        const svg_loaded = computed < boolean > (() => {
            if (ready.value) {
                return svg_counter.value == courses.value.length
            }
            return false
        })
        const store = useStore()
        const router = useRouter()

        const ready = ref(false)
        const courses = ref < Array < Course >> ([]);
        const timedout = ref < boolean > (false)
        const terms_of_use_url = ref(process.env.VUE_APP_TERMS_OF_USE_URL)
        const privacy_policy_url = ref(process.env.VUE_APP_PRIVACY_POLICY_URL)
        const logout = () => {
            store.dispatch(ActionTypes.LOGOUT)
            localStorage.removeItem('preview')
            router.replace('/login')
        }
        onMounted(() => {
            CampGeoApiService.getCourseList().then(data => {
                    if (data.length <= 1) {
                        router.push('chapters')
                    } else {
                        courses.value = data;
                        //If only one item, go to that course
                        if (courses.value.length == 1) {
                            router.push(getCoursePageUrl(courses.value[0]))
                        }
                        ready.value = true
                        setupStripe()
                    }
                })
                .catch(e => {
                    console.error(e)
                    CampGeoApiService.getCourseList().then(data => {
                            courses.value = data;
                            ready.value = true
                        })
                        .catch(e => {
                            console.error(e)
                            logout()
                        })
                })
            setTimeout(() => {
                timedout.value = true;
            }, 5000)
        })

        return {
            courses,
            logout,
            svg_loaded,
            svgLoaded,
            timedout,
            ready,
            svg_counter,
            terms_of_use_url,
            privacy_policy_url,
            is_logged_in,
        }
    },
    methods: {
        refresh: (ev: CustomEvent) => {
            setTimeout(() => {
                ev.detail.complete();
            }, 3000);
        },
    },
    computed: {
        visible_courses() {
            if (this.search_value.length > 0) {
                return this.courses.filter(c => {
                    return c.title.toLowerCase().includes(this.search_value.toLowerCase())
                })
            }

            return this.courses;

        },
    },
    components: {
        IonList,
        CourseListItem,
        PageTemplate,
        IonIcon,
        IonGrid,
        IonCol,
        ContactUsListItem,
        IonRow
    },
});
