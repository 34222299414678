export enum ActionTypes {
    TOGGLE_AUDIO_PLAY = 'TOGGLE_AUDIO_PLAY',
    SET_IS_PLAYING = 'SET_IS_PLAYING',
    GO_BACKWARD_10_SECONDS = 'GO_BACKWARD_10_SECONDS',
    GO_FORWARD_10_SECONDS = 'GO_FORWARD_10_SECONDS',
    SET_PLAYING_EPISODE = 'SET_PLAYING_EPISODE',
    SET_AUDIO_PLAYING = "SET_AUDIO_PLAYING",
    SET_PLAYING_EPISODE_CURRENT_TIME = "SET_PLAYING_EPISODE_CURRENT_TIME",
    CHANGE_CURRENT_TIME = "CHANGE_CURRENT_TIME",
    LOGIN = "LOGIN",
    LOGOUT = "LOGOUT",
    SETUP_CAMP_GEO_AUTHENTICATION = "SETUP_CAMP_GEO_AUTHENTICATION",
    REGISTER_USER = "REGISTER_USER",
    CREATE_LISTEN_ACTION = "CREATE_LISTEN_ACTION",
    START_PLAYING = "START_PLAYING",
    PAUSE_AUDIO = "PAUSE_PLAYER",
    PLAY_AUDIO = "PLAY_AUDIO",
    SET_AUDIO_PLAYBACK_SPEED = "SET_AUDIO_PLAYBACK_SPEED",
    SET_FACEBOOK_IS_LOADED = "SET_FACEBOOK_IS_LOADED",
    SET_USER = "SET_USER",
    CHANGE_CURRENT_TIME_WITH_MEDIA = "CHANGE_CURRENT_TIME_WITH_MEDIA",
    STOP_AUDIO = "STOP_AUDIO",
    CLEAR_PLAYING_EPISODE = "CLEAR_PLAYING_EPISODE"
}