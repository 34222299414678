import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-12f02bde"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.showOverlay)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "image-zoom-container",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.endZoom && _ctx.endZoom(...args)))
      }, [
        _createVNode(_component_ion_button, {
          color: "white",
          fill: "clear",
          size: "medium",
          class: "py-0 pr-5 fixed r-0 m-auto z-100 end-zoom-button"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              slot: "icon-only",
              icon: _ctx.closeOutline
            }, null, 8, ["icon"])
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: "image-container",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.endZoom && _ctx.endZoom(...args)))
        }, [
          _createVNode(_component_swiper, {
            modules: _ctx.modules,
            onSwiper: _ctx.setSwiperInstance,
            zoom: "",
            class: "bg-transparent b-transparent"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_swiper_slide, {
                zoom: "",
                class: "bg-transparent b-transparent"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: _ctx.overlayImage,
                    class: "bg-transparent b-transparent"
                  }, null, 8, _hoisted_1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modules", "onSwiper"])
        ])
      ]))
    : _createCommentVNode("", true)
}