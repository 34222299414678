import { store, useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";

export interface FacebookResponse {
    status: string,
    authResponse: {
        accessToken: string,
        expiresIn: string
        signedRequest: string
        userID: string
  }
}

export const LOADED = false;
export const facebook_app = process.env.VUE_APP_FACEBOOK_APP_ID
export const initFacebook = () => {
    window.FB.init({
        appId      : facebook_app,
        cookie     : true,
        xfbml      : true,
        version    : 'v14.0'
      });
      const store = useStore()
      store.dispatch(ActionTypes.SET_FACEBOOK_IS_LOADED, true)
}
export const runFbSdk = () => {
    const d = document;
    const s = 'script';
    const id = 'facebook-jssdk';
    let js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
        js = 'hi'
        return;
    }
    js = d.createElement(s); 
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js?status=0";
    if(fjs.parentNode) {
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    }
    fjs = d.getElementsByTagName(s)[0];
    
};