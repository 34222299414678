
export interface PodcastMedia {
    id: number,
    title: string
    image_url: string,
    description: string,
    timestamp: number,
    source_name: string,
    source_url: string,
}

export interface Podcast {
    mp3_url: string
    media: Array<PodcastMedia>,
    duration: number,
    id: number
}
export interface ListenActionManager {
    has_finished: boolean,
    has_listened: boolean,
    has_listened_to_all: boolean,
    last_left_off_at: number
}
export interface Episode {
    id: number,
    title: string,
    summary: string,
    read: boolean,
    podcast: Podcast,
    chapter_id: number,
    chapter_title: string,
    chapter_number: number,
    episode_number: number,
    duration?: number,
    chapter_icon?: string,
    listen_action_manager?: ListenActionManager,
    transcript?: string,
    transcript_url?: string,
    next_episode_id?: number
    
}

export const getLastLeftOff = (episode: Episode): number => {
    return episode.listen_action_manager ? episode.listen_action_manager.last_left_off_at : 0;
}