
import { Episode } from '@/data/episodes';
import { IonIcon, IonItem, IonGrid, IonCol, IonRow } from '@ionic/vue';
import { notListenedTo, halfListenedTo, finishedListening} from "../icons/carbon_icons/carbon_icons"
import { chevronForward, ellipse } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import {secondsToString} from "../hooks/seconds-to-string"
import { Chapter, getIconUrl } from '@/data/chapters';
import InlineSvg from 'vue-inline-svg';

export default defineComponent({
  name: 'EpisodeListItem',
  components: {
    IonItem,
    IonGrid,
    IonCol,
    IonRow,
    IonIcon
  },
  props: {
    episode: Object as () => Episode,
    chapter: Object as () => Chapter
  },
  setup(props, context)  {
    const listen_icon = computed(() => {
      if(props.episode) {
        if(props.episode?.listen_action_manager?.has_listened_to_all) {
          return finishedListening
        } else if (props.episode?.listen_action_manager?.has_listened) {
          return halfListenedTo
        } 
      }

      return notListenedTo
    })

    const episode_duration = computed(() => {
      if(props.episode && props.episode.duration) {
        return secondsToString(props.episode.duration)
      }
      return null;
    })
    return {
      listen_icon,
      episode_duration
    }
  },  
  data() {
    return { chevronForward, ellipse }
  }
});
