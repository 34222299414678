
import { computed, defineComponent } from "@vue/runtime-core";
import {  IonButtons, IonToolbar, IonTitle, IonButton, IonIcon, IonMenu, IonMenuButton, IonItem, IonLabel, IonList, IonThumbnail } from '@ionic/vue';
import { useStore } from "@/store";
import { ActionTypes } from "@/store/action-types";
import { tenBackward} from "../icons/carbon_icons/carbon_icons"
import { chevronBack } from "ionicons/icons";
import { Transition, ref, onMounted, watch } from "vue";
import { getIconUrl, getIconUrlFromChapterId } from '@/data/chapters';
const campgeo_logo_light = "../../resources/campgeo-logo-light.svg"
const campgeo_logo_dark = "../../resources/campgeo-logo.svg"
import InlineSvg from 'vue-inline-svg';
import { useRoute, useRouter } from "vue-router";
import { CampGeoApiService } from "@/services/CampGeoApiService";
import UserAgreement from "./UserAgreement.vue";
import { useLogoutApi } from '../views/resources/LogoutApi';

export default defineComponent({
    name: 'PageTemplate',
    props: {
        title: {
            type: String,
            required: false,
            description: "Title of page to be shown in the header"
        },
        backButtonLabel: {
            type: String,
            required: false,
            default: null,
            description: "Label for back button"
        },
        hideBackButton: {
            type: Boolean,
            required: false,
            default: false,
            description: "Hide back button"
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
            description: "True if loading"
        },
        noHeader: {
            type: Boolean,
            required: false,
            default: false,
            description: 'turn off header'
        },
        defaultBackUrl: {
            type: String,
            required: false,
            default: null,
            description: "Default go back url"
        },
        ionContentClass: {
            type: String, 
            required: false,
            default: ""
        },
        noScrollingInContent: {
            type: Boolean,
            required: false,
            default: false,
            description: "add overflow hidden to content if true"
        },

        hideMenu: {
            type: Boolean,
            required: false,
            default: false,
            description: "Show menu"
        }
    },
  components: {
    IonButtons,
    IonToolbar,
    IonTitle,
    IonButton,
    IonIcon,
    Transition,
    IonMenu,
    IonItem,
    InlineSvg,
    IonLabel,
    IonList,
    IonThumbnail,
    UserAgreement,
    IonMenuButton
  },
    data() {
        return {
            getBackButtonText: () => {
                const win = window as any;
                const mode = win && win.Ionic && win.Ionic.mode;
                return mode === 'ios' ? 'Inbox' : '';
            },
            tenBackward,
            chevronBack,
            dark: false
        }
    },
    
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const route = useRoute();
        const play_icon = computed(() => store.getters.play_icon)
        const playing_episode = computed(() => store.getters.playing_episode);

        const contact_us_url = ref(process.env.VUE_APP_CONTACT_US_PAGE);
        const terms_of_use_url =  ref(process.env.VUE_APP_TERMS_OF_USE_URL);
        const privacy_policy_url =  ref(process.env.VUE_APP_PRIVACY_POLICY_URL);

        const playButtonClick = () => {
            store.dispatch(ActionTypes.TOGGLE_AUDIO_PLAY);
        };
        
        const goBackwardTenSecondsClick = () => {
            store.dispatch(ActionTypes.GO_BACKWARD_10_SECONDS);
        }

        const icon_url = ref<string>("");
        const random_number = ref<number>();
        
        const show_user_agreement_page = ref(false)

        const showUserAgreementPage = () => {
            show_user_agreement_page.value = true
        }
        const userAcceptedAgreement = () => {
            show_user_agreement_page.value = false
        }

        const confirmUser = () => {
            if(typeof store.getters.user == 'undefined' && store.state.camp_geo_authentication?.user_id) {
                CampGeoApiService.getUser(store.state.camp_geo_authentication.user_id).then(user => {
                    store.dispatch(ActionTypes.SET_USER, user)
                    if(user.user_profile.accepted_user_agreement != true) {
                        showUserAgreementPage()
                    }
                })
            }
        }
        const display_loading = ref(false)

        onMounted(() => {
            confirmUser()
            if(playing_episode.value) {
                icon_url.value = getIconUrlFromChapterId(playing_episode.value.chapter_id)
            }
            random_number.value = Math.random();

            if(typeof route.query.from === 'string') {
                localStorage.setItem('ad_origin', route.query.from);
            }
            if(typeof route.query.preview === 'string') {
                localStorage.setItem('preview', route.query.preview)
            }

            display_loading.value = props.loading
        })
        
        const goBack = () => {
            if(window.history.state.back == null) {
                router.push(props.defaultBackUrl)
            } else {
                router.back()
            }
        }

        const use_logout_api = useLogoutApi()

        watch(() => props.loading, newVal => {
            if(newVal) {
                display_loading.value = true;
            } else {
                setTimeout(() => {
                    display_loading.value = false
                }, 200)
            }
        })

        return {
            play_icon,
            playButtonClick,
            goBackwardTenSecondsClick,
            playing_episode,
            icon_url,
            random_number,
            goBack,
            show_user_agreement_page,
            userAcceptedAgreement,
            terms_of_use_url,
            privacy_policy_url,
            contact_us_url,
            ...use_logout_api,
            display_loading
            
        }
    },
    computed: {

        show_menu_button() {
            return this.hideMenu ? false : !this.loading && !this.show_user_agreement_page
        },
        show_back_button() {
            return !this.is_home && !this.hideBackButton;
        },
        ion_content_class() {
            let ion_content_class =`ion-content ${this.ionContentClass} gradient-background`;
            if(this.noScrollingInContent) {
                ion_content_class += "overflow-hidden "
            }
            return ion_content_class 
        },  
        is_home() {
            return this.$route.path == '/';
        },
        show_ion_footer() {
            return this.playing_episode || this.$slots.footer;
        },
        body_color() {
            return this.dark ? 'black' : 'white'
        },  
        cssVars() {
            return {
                '--body-color': this.body_color,
            }
        },
        campgeo_logo_url() {
            if(this.dark) {
                return "../../resources/campgeo-logo.svg"
            }
            return "../../resources/campgeo-logo-light.png"
        }
    }
})
