
import { useRoute, useRouter } from 'vue-router';
import { IonGrid, IonRow, IonInput, IonIcon } from '@ionic/vue';
import { information, musicalNote, search } from 'ionicons/icons';
import { Chapter } from '../data/chapters';
import { computed, defineComponent, provide, ref, onMounted } from 'vue';
import PageTemplate from './PageTemplate.vue';
import LargeChapterCard from "../components/ChapterPage/LargeChapterCard.vue"
import ChapterEpisodeList from "../components/ChapterPage/ChapterEpisodeList.vue"
import { CampGeoApiService } from '@/services/CampGeoApiService';
import { ActionTypes } from '@/store/action-types';
import { useStore } from '@/store';
export default defineComponent({
  data() {
    return {
      information,
      musicalNote,
      search,
      search_value: ""
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const chapter = ref<Chapter|null>();
    const ready = ref(false)
    const chapter_svg_loaded = ref<boolean>(false)
    const episode_svgs_loaded = ref<boolean>(false)
    const svg_loaded = computed(() => {
      return chapter_svg_loaded.value && episode_svgs_loaded.value
    })
    const chapterSvgLoaded = () => {
      chapter_svg_loaded.value = true
    }
    const episodeSvgsLoaded = () => {
      episode_svgs_loaded.value = true
    }
    const timedout = ref<boolean>(false)
    onMounted(() => {
      setTimeout(() => {
        timedout.value = true;
      }, 5000)
    CampGeoApiService.getChapterById(parseInt(route.params.id as string, 10)).then(c => {
      chapter.value = c;
      ready.value = true
    })
    .catch(e => {
      console.error(e)
      CampGeoApiService.getChapterById(parseInt(route.params.id as string, 10)).then(c => {
        chapter.value = c;
        ready.value = true
      })
    });
    })
    provide('chapter', chapter);
    return { chapter, ready, chapter_svg_loaded, episode_svgs_loaded, chapterSvgLoaded, timedout }
  },
  components: {
    PageTemplate,
    LargeChapterCard,
    ChapterEpisodeList,
},
});
