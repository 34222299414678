
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'gradient-background',

    props: {
        noGradient: {
            type: Boolean,
            required: false,
            default: false,
            description: 'If true, the middle of the page is just all grey'
        }
    },
})
