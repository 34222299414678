
  import { defineComponent, ref } from 'vue';
  import { IonButton, IonGrid, IonRow, IonCol } from '@ionic/vue';
  import patentPendingFooter from './patent-pending-footer.vue';
  import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'swiper/css/zoom'
  import LandingPageSlideshow from "./landing-page-slideshow-ynp.vue";
  

  export default defineComponent({
      components: {
          
          LandingPageSlideshow
      },
      props: {
          loading: {
              type: Boolean,
              required: false,
              default: false,
              description: "When something is loaded"
          }
      }
  })
  