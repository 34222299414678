
  /*global FB*/
  import PageTemplate from './PageTemplateLandingPage.vue';
  // import PageTemplate from './PageTemplate.vue';
  import { defineComponent, ref, onMounted } from 'vue';
  import LoginToolbar from './components/login-toolbar.vue';
  import LoginContent from './components/login-content.vue';
  import LandingPageContent from './components/landing-page-content.vue';
  import LandingPageToolbar from './components/landing-page-toolbar.vue';
import { useRouter } from 'vue-router';
import GradientBackground from './components/gradient-background.vue';
  
export default defineComponent({
    setup() {
      const loading = ref(true)
      const router = useRouter()

      const logoLoaded = () => {
        loading.value = false
      }
        return {
            loading,
            logoLoaded
        }
    },
  components: {
    PageTemplate,
    LoginContent,
    LoginToolbar,
    LandingPageContent,
    LandingPageToolbar,
    GradientBackground,
  },
})
