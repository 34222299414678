
import { ActionTypes } from '@/store/action-types';
import { LoginCredentials } from '@/store/camp-geo-authentication';
import { defineComponent, nextTick, onMounted, ref } from 'vue';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';
import PageTemplate from './PageTemplate.vue';

export default defineComponent({
  components: { PageTemplate },
    name: 'twitter-callback',
    setup() {
        onMounted(() => {
        const route = useRoute();
        const store = useStore();
        const router = useRouter();
        
        const oauth_token = typeof route.query.oauth_token !== 'string'  ? undefined : route.query.oauth_token
        const oauth_verifier = typeof route.query.oauth_verifier!== 'string' ? undefined : route.query.oauth_verifier

        const twitter_oauth_token = localStorage.getItem('twitter_oauth_token')
        const twitter_oauth_token_secret = localStorage.getItem('twitter_oauth_token_secret')
        if(typeof twitter_oauth_token !== "string" || typeof twitter_oauth_token_secret !== "string"){
            router.push('/landing-page')
            return;
        }
        const ad_origin = localStorage.getItem('ad_origin')
        const lc: LoginCredentials = {
            twitter_token: oauth_token,
            twitter_verifier: oauth_verifier,
            twitter_oauth_token: twitter_oauth_token,
            twitter_oauth_token_secret: twitter_oauth_token_secret,
            ad_origin: ad_origin == "" ? null : ad_origin

        }
        
        
      store.dispatch(ActionTypes.LOGIN, lc)
      .then(() => {
        localStorage.removeItem('ad_origin')

        nextTick(() => {
            const postLoginRedirect = localStorage.getItem('postLoginRedirect');
            if(postLoginRedirect) {
              localStorage.removeItem('postLoginRedirect');
              router.push(postLoginRedirect)
            } else {
              router.push('/');
            }
        })
      })
      .catch((e: any) => {
        router.push('/landing-page')
      })

    })
}
})
