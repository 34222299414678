import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3533fefe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "px-0" }
const _hoisted_3 = { class: "list-background-square" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_course_banner = _resolveComponent("course-banner")!
  const _component_ChapterListItem = _resolveComponent("ChapterListItem")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    loading: !_ctx.ready,
    "back-button-label": "Courses",
    defaultBackUrl: "/"
  }, {
    content: _withCtx(() => [
      (_ctx.ready && _ctx.course)
        ? (_openBlock(), _createBlock(_component_course_banner, {
            key: 0,
            course: _ctx.course
          }, null, 8, ["course"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              (_ctx.course)
                ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.course.chapters, (chapter) => {
                        return (_openBlock(), _createBlock(_component_ChapterListItem, {
                          key: chapter.id,
                          chapter: chapter,
                          onSvgLoaded: _ctx.svgLoaded
                        }, null, 8, ["chapter", "onSvgLoaded"]))
                      }), 128))
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}