import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_toolbar = _resolveComponent("login-toolbar")!
  const _component_gradient_background = _resolveComponent("gradient-background")!
  const _component_landing_page_content = _resolveComponent("landing-page-content")!
  const _component_page_template = _resolveComponent("page-template")!

  return (_openBlock(), _createBlock(_component_page_template, {
    title: "Landing Page",
    "hide-back-button": "",
    "hide-menu": "",
    "ion-content-class": "login-content"
  }, {
    toolbar: _withCtx(() => [
      _createVNode(_component_login_toolbar, { onLogoLoaded: _ctx.logoLoaded }, null, 8, ["onLogoLoaded"])
    ]),
    content: _withCtx(() => [
      _createVNode(_component_gradient_background),
      _createVNode(_component_landing_page_content)
    ]),
    _: 1
  }))
}