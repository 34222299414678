
  import { defineComponent, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
  import PageTemplate from './PageTemplate.vue';
import { isSupportedBrowser } from './resources/IsSupportedBrowser';
  
  export default defineComponent({
    components: { PageTemplate },
      name: 'unsupported-browser',
      setup() {
        const router = useRouter()
        const route = useRoute()
        const copied = ref(false)
        const url = ref("")
        onMounted(() => {
            const from = localStorage.getItem('ad_origin');
            const postLoginRedirect = localStorage.getItem('postLoginRedirect');

            url.value = window.location.origin + "/landing-page";  
            if(from || postLoginRedirect) {
                url.value += "?"
            }
            if(from) {
                url.value +=`from=${from}&`
            }
            if(postLoginRedirect) {
                url.value +=`redirect=${postLoginRedirect}`
            }
            const is_supported_browser = isSupportedBrowser()
            const new_query = Object.assign({
                from: from,
            }, route.query);
            if(is_supported_browser) {
                router.push({name: 'Login', query: new_query})
            } else {
                router.replace({path: route.path, query: new_query})
            }
        })
        const copyUrl = () => {
            navigator.clipboard.writeText(url.value);
            copied.value = true
        }

        return {
            copyUrl,
            copied
        }
      }
  })
  