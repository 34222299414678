
import { Course } from "@/data/courses";
import { defineComponent } from "@vue/runtime-core";
import ChapterListItem from '../ChapterListItem.vue';
import { IonList } from "@ionic/vue"
import { computed, ref } from "vue"

export default defineComponent({
    props: {
        searchValue: {
            type: String,
            required: false,
            default: "",
            description: "Searching in course"
        },
        course: {
            type: Object as () => Course, 
            required: true,
            description: "course"
        }
    },
    setup(props, context) {
        const visible_chapters = computed(() => {
            if(props.course && props.course.chapters) {
                if(props.searchValue.length > 0) {
                    return props.course.chapters.filter(e => {
                        return e.title.includes(props.searchValue)
                    })
                } else {
                    return props.course.chapters
                }
            }
            return [];
        })

        const svg_counter = ref<number>(0)
        const svg_loaded = ref<boolean>(false)
        const svgLoaded = () => {
            svg_counter.value++
            if(svg_counter.value == visible_chapters.value.length) {
                svg_loaded.value = true
                context.emit('svgLoaded')
            }
        }

        return {
            visible_chapters,
            svgLoaded,
            svg_loaded
        }
    },
    components: {
        ChapterListItem,
        IonList
    }
})
