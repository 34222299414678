
import { Course } from '@/data/courses';
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        course: {
            type: Object as () => Course, 
            required: true,
            description: "course"
        }
    },
    setup(props, context) {
        const header_image = computed(() => {
          return typeof props.course.header_file_url != 'undefined' && props.course.header_file_url.length > 0 ? props.course.header_file_url : null;
        })
        return {
            header_image
        }
    }
})
